import React, {Component} from 'react';
import "assets/css/cookies-popup.css"
import {NavLink} from "react-router-dom";
import T from "app/i18n/T";

export class CookiesPopup extends Component {

    acceptCookies = () => {
        localStorage.setItem("acceptCookies", "1");
        this.forceUpdate()
    };

    render() {
        return localStorage.getItem("acceptCookies") !== "1" ? <div className="cookies-popup">
                <div className="card">
                    <div className="card-header">
                        {T('This website is using cookies.')}
                    </div>
                    <div className="card-body">
                        <p className="card-text">
                            {T('By using this website you accept that it will be able to save small portions of data on your computer(cookies). More info about it you can find on ')}
                            <NavLink exact to="/cookies" target="_blank">
                                {T('Cookie policy')}
                            </NavLink>
                        </p>
                        <div className="text-right">
                            <a href="http://google.pl" className="btn btn-border-color ebok-color mr-2">{T('Decline')}</a>
                            <div className="btn btn-primary pointer" onClick={this.acceptCookies}>{T('Accept')}</div>
                        </div>
                    </div>
                </div>
            </div>
            : ""
    }
}