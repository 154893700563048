import React, { Component } from "react";
import { mapStorageToProps } from "app/redux/mappers";
import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";
import { connect } from "react-redux";
import { ServiceCardNew } from "./ServiceCardNew";
import T from "../../i18n/T";
import Utils from "../../Utils";

export class CustomerServices extends Component {
  noServices() {
    return <div className="d-flex justify-content-center"><h3 className="ebok-color">{T('No active services')}</h3></div>;
  }

  renderServiceCards() {
    const { dashboard } = this.props;
    const services = Utils.sortContracts(this.props.services);

    if (!services || !dashboard || !services.length) {
      return this.noServices();
    }

    return (
      <div>
        {services.map((contract, contractIndex) => {
          if (contract) {
            const sortedProducts = contract.contract.products.sort((a, b) => a.name.localeCompare(b.name));
            return (
              <div className="container bgr-grey mb-4 pt-3" key={contractIndex}>
                <h3 className="ebok-color txt-violet-vbig ml-3 mb-0">{T("Agreement")}: {contract.contract.number} </h3>
                <div className="row card-paddings">
                  {sortedProducts.flatMap((product, productIndex) => {
                    if (product) {
                      return product.subscriptions.map((serviceItem, serviceItemIndex) => (
                        <ServiceCardNew
                          key={`${contractIndex}-${productIndex}-${serviceItemIndex}`} // Unikalny klucz na podstawie indeksów
                          address={contract.serviceLocation?.address || null}
                          item={serviceItem}
                        />
                      ));
                    } else {
                      return [];
                    }
                  })}
                </div>
              </div>
            );
          } else {
            return [];
          }
        })}
      </div>
    );
  }

  render() {
    if (this.props.services && this.props.dashboard) {
      return (this.renderServiceCards());
    }
    return this.noServices();
  }
}

export default connect(mapStorageToProps, mapDispatchToProps)(CustomerServices);
