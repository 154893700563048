import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import * as qs from "query-string";
import {SecApi} from "app/api/SecApi";
import ActionConfirmation from "app/app-components/common/ActionConfirmation";


export class ViewVerifyToken extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            accountActivated: false,
            backendMessage:""
        }
    }

    componentDidMount() {
        this.setState({
                token: qs.parse(this.props.location.search, {ignoreQueryPrefix: true})["token"]
            }, () => {
                SecApi.verifyToken(this.state.token).then((json) => {
                    let errMessages = json.meta.messages.filter((message => {
                        return message.type === "ERROR"
                    }));
                    if (errMessages.length === 0) {
                        this.setState({accountActivated: true})
                    } else {
                        this.setState({
                            backendMessages: errMessages.map((message) => {
                                return message.text
                            })
                        })

                    }
                })
            }
        )
    }

    render() {
        return <ActionConfirmation success={this.state.accountActivated}
                                   message={this.state.backendMessages}
                                   title={this.state.accountActivated ? "Twoje konto zostało aktywowane" : "Twój token wygasł, lub aktywacja się nie powiodła. Błąd jaki wystąpił:"}
                                   loginButton={this.state.accountActivated} />

    }
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(ViewVerifyToken));