import React, {Component} from 'react';
import {CustApi} from "app/api/CustApi";
import DataTable from "app/app-components/common/DataTable/DataTable";
import {dataTableLang} from "app/i18n/DataTableLang";
import T from "app/i18n/T";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {connect} from "react-redux";
import Utils from "app/Utils";

class ViewBilling extends Component {

    render() {

        return <>
            <div className="container bgr-grey pt-3 pb-3">
                <div className="bgr-w">
                    <DataTable
                        id="ViewCustomerHistoryTable"
                        api={CustApi.getHistory}
                        cols={[
                            {
                                header: T("Operation date"),
                                field: "operationDate",
                                formatter: (value, row) => Utils.getFormattedDate(value)
                            },
                            {header: T("Operation name"), field: "operationName", mobileHeader: true}
                        ]}
                        labels={dataTableLang()}
                        defaultSort={{field: 'startT', direction: 'DESC'}}>
                    </DataTable>
                </div>
            </div>
        </>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewBilling)