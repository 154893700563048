import React, {Component} from "react";
import {SecApi} from "app/api/SecApi";
import T from "app/i18n/T";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";


class ViewConsultantLogIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false
        }
    }


    login = (event) => {
        event.preventDefault();

        const data = {
            _provider: 'ebok-consultant',
            _dbConsultant: {
                login: event.target.login.value,
                password: event.target.password.value,
                token: event.target.customerToken.value,
                crmNumber: event.target.crmCustomerNumber.value
            }
        };

        SecApi.login(data).then(
            (json) => {
                this.props.pushToast('s', T('Log in success!'));
                this.props.setUser(json.data);
                this.props.history.push('/')
            },
            (error) => this.props.pushToast('e', error)
        );
    };

    render() {
        return <>

            <div className="row d-flex justify-content-center">
                <div className="col col-12 col-sm-8 col-lg-6 col-xl-4">

                        <p className="ebok-color profile-name-card">
                            {T('Consultant Log-in')}
                        </p>

                        <form className="form-signin mt-4" onSubmit={this.login}>
                            <span className="reauth-email"> </span>
                            <input
                                className="form-control login-box"
                                id="login" name="login" required=""
                                placeholder={T('Consultant username')} autoFocus=""/>
                            <input
                                className="form-control login-box"
                                type="password" id="password" name="password"
                                required="" placeholder={T('Password')}/>
                            <input
                                className="form-control login-box"
                                type="text" id="customerToken" name="customerToken"
                                required="" placeholder={T('Customer token')}/>
                            <input
                                className="form-control login-box"
                                type="text" id="crmCustomerNumber" name="crmCustomerNumber"
                                required="" placeholder={T('Customer CRM number')}/>

                            <button className="mt-3 btn btn-primary btn-block btn-lg btn-signin background-main"
                                    type="submit" onClick={this.submit}>
                                {T('LOG IN')}
                            </button>

                        </form>

                    <div className="mt-3 w-100 d-inline-flex align-items-center">
                        <div className="grey-stripe w-100"/>
                        <p className="txt-log-wersal mt-4 text-center mb-4 px-3">
                            {T('OR')}
                        </p>
                        <div className="grey-stripe w-100"/>
                    </div>

                    <NavLink exact to="/consultant/registration"
                             className="ebok-color btn-border-color btn-reg w-100 d-flex justify-content-center align-items-center pointer">
                        {T('SIGN UP')}
                    </NavLink>

                </div>
            </div>

        </>

    }


}


export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewConsultantLogIn);
