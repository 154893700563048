import React, { Component } from "react";
import { OperApi } from "app/api/OperApi";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { Markup } from "interweave";

class BuyService extends Component {


  getData = (lang) => {
    OperApi.getArticle("dashboardPanel", lang).then((json) => {
      this.setState({ article: json.data });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.lang !== prevProps.lang && this.getData(prevProps.lang);
  }

  componentDidMount() {
    this.getData(this.props.lang);
  }

  render() {
    if (!this.state) {
      return <></>;
    }

    return <div className="background-main h-100 px-4 pb-4 pb-xl-0">
      <div className="pt-3 pb-4">
        <span className="txt-white-big">{this.state.article.title}</span>
        <span className="txt-white-small mb-0 txt-news-short-3 mt-2"><Markup
          content={this.state.article.content} /></span>
      </div>
      <div className="d-flex justify-content-center pb-lg-4">
        <a href={this.state.article.link}
           className="btn btn-block btn-lg btn-signin-white-thin ebok-color w-100">
          {this.state.article.linkText}
        </a>
      </div>
    </div>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(BuyService);
