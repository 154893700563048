import { Api } from "./Api";

export class SecApi extends Api {

  static apiUrl = (window.location.port === "3000" && window.var.SECAPI_URL) || "/sec-api";

  static theme() {
    return SecApi.get("/theme");
  }

  static user() {
    return SecApi.get("/user");
  }

  static login(credentials) {
    return SecApi.post("/login", credentials);
  }

  static logout() {
    return SecApi.get("/logout").then();
  }

  static register(registerData) {
    return SecApi.post("/user/register", registerData);
  }

  static registerConsultant(registerData) {
    return SecApi.post("/consultant/register", registerData);
  }

  static verifyToken(token) {
    return SecApi.get("/user/register/confirm/" + token);
  }

  static generateToken() {
    return SecApi.get("/user/contact/token/generate");
  }

  static resetPassword(resetData) {
    return SecApi.post("/user/password/forget", resetData);
  }

  static resetPasswordCheckToken(token) {
    return SecApi.post("/user/password/checkToken/" + token);
  }

  static resetPasswordConfirm(confirmData) {
    return SecApi.post("/user/password/change", confirmData);
  }

}
