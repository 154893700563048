import {store} from "app/redux/redux";
import {translations} from "./i18n"

function T(key, forceLang) {

    let lang = forceLang ? forceLang : store.getState().lang;

    if (lang !== 'en') {
        return translations[lang][key] ? translations[lang][key] : key;
    } else {
        return key;
    }

}

export default T;