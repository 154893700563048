import T from "app/i18n/T";
import React from "react";
import { FormFeedback, Input, InputGroup } from "reactstrap";
import { getMsg, isValid } from "app/app-components/common/Validation";
import * as PropTypes from "prop-types";

export const RegistrationStepWithoutSocial = props => {
  return <div
    className="col step-box-stripe-v1 col-12 col-md-6 col-xl-4 px-3 px-lg-5 mt-3 pb-4">

    <div className="d-lg-flex align-items-lg-start h-25">
      <div className="row">
        <div className="col col-12">
          <div className="icon-w-text">
            <div>
              <p className="circle-number">3</p>
              <i className="fas fa-lg fa-circle ebok-color" />
            </div>
            <div>
              <p className="step-txt pl-2">
                {T("Enter your e-mail address")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="multiCollapseExample3">
      <div className="d-flex align-items-start">
        <InputGroup>
          <Input type="text"
                 onChange={props.onChange}
                 className="w-100 txt-input form-control login-box mb-2"
                 placeholder={T("E-mail address")} invalid={isValid("email")} />
          <FormFeedback>{getMsg("email")}</FormFeedback>
        </InputGroup>
      </div>
    </div>
  </div>;
};
RegistrationStepWithoutSocial.propTypes = { onChange: PropTypes.func };
