import {Api} from "./Api";

export class OperApi extends Api {


	static apiUrl = (window.location.port === '3000' && window.var.OPERAPI_URL) || '/oper-api';

	static theme() {
		return OperApi.get('/theme')
	}

    static getOperatorContact() {
        return OperApi.get('/contact')
    }

	static getOperatorConfig() {
		return OperApi.get('/config')
	}

	static getArticle(articleName, lang) {
		return OperApi.get('/article/' + articleName + "/" + lang)
	}


}
