import ViewConsultantLogIn from "app/app-views/annonymous/ViewConsultantLogIn";
import ViewLogIn from "app/app-views/annonymous/ViewLogIn";
import ViewRegistration from "app/app-views/annonymous/ViewRegistration";
import {ViewVerifyToken} from "app/app-views/annonymous/ViewVerifyToken";
import {ViewChat} from "app/app-views/annonymous/ViewChat";
import {ViewHowToLogin} from "app/app-views/annonymous/ViewHowToLogin";
import {ViewHelp} from "app/app-views/annonymous/ViewHelp";
import ViewForgotPassword from "app/app-views/annonymous/ViewForgotPassword";
import {ViewChangePassword} from "app/app-views/annonymous/ViewChangePassword";
import {ViewCookies} from "app/app-views/common/ViewCookies";
import {ViewPrivacyPolicy} from "app/app-views/common/ViewPrivacyPolicy";
import {ViewRegulations} from "app/app-views/common/ViewRegulations";
import ViewConsultantRegistration from "app/app-views/annonymous/ViewConsultantRegistration";


export const routes = [
    {
        key: 'login',
        exact: true,
        path: "/",
        component: ViewLogIn
    },
    {
        key: 'registration',
        exact: true,
        path: "/registration",
        component: ViewRegistration
    },
    {
        key: 'VerifyToken',
        exact: true,
        path: "/tokenVerification",
        component: ViewVerifyToken
    },
    {
        key: 'chat',
        exact: true,
        path: "/chat",
        component: ViewChat
    },
    {
        key: 'howToLogin',
        exact: true,
        path: "/howToLogin",
        component: ViewHowToLogin
    },
    {
        key: 'help',
        exact: true,
        path: "/help",
        component: ViewHelp
    },
    {
        key: 'forgotPassword',
        exact: true,
        path: "/forgotPassword",
        component: ViewForgotPassword
    },
    {
        key: 'changePassword',
        exact: true,
        path: "/changePassword",
        component: ViewChangePassword
    },
    {
        key: 'cookies',
        exact: true,
        path: "/cookies",
        component: ViewCookies
    },
    {
        key: 'ConsultantLogIn',
        exact: true,
        path: "/consultant/login",
        component: ViewConsultantLogIn
    },
    {
        key: 'ConsultantRegistration',
        exact: true,
        path: "/consultant/registration",
        component: ViewConsultantRegistration
    },
    {
        key: 'privacyPolicy',
        exact: true,
        path: "/privacy",
        component: ViewPrivacyPolicy
    },
    {
        key: 'regulations',
        exact: true,
        path: "/regulations",
        component: ViewRegulations
    }
];