import ViewDashboard from "app/app-views/authorized/ViewDashboard";
import ViewCustomerData from "app/app-views/authorized/ViewCustomerData";
import { ViewInvoiceType } from "app/app-views/authorized/ViewInvoiceType";
import { ViewPayInvoices } from "app/app-views/authorized/ViewPayInvoices";
import { ViewBuyServices } from "app/app-views/authorized/ViewBuyServices";
import ViewServices from "app/app-views/authorized/ViewServices";
import ViewPayments from "app/app-views/authorized/ViewPayments";
import ViewMessages from "app/app-views/authorized/ViewMessages";
import ViewBilling from "app/app-views/authorized/ViewBilling";
import { ViewCookies } from "app/app-views/common/ViewCookies";
import { ViewPrivacyPolicy } from "app/app-views/common/ViewPrivacyPolicy";
import ViewCustomerHistory from "app/app-views/authorized/ViewCustomerHistory";
import { ViewRegulations } from "app/app-views/common/ViewRegulations";


export const routes = [
  {
    key: "dashboard",
    exact: true,
    path: "/",
    has: "CUSTOMER",
    component: ViewDashboard
  },
  {
    key: "CustomerData",
    exact: true,
    path: "/customer/data",
    component: ViewCustomerData
  },
  {
    key: "CustomerHistory",
    exact: true,
    path: "/customer/history",
    component: ViewCustomerHistory

  },
  {
    key: "CustomerInvoiceType",
    exact: true,
    path: "/customer/invoiceType",
    component: ViewInvoiceType

  },
  {
    key: "PayInvoice",
    exact: true,
    path: "/invoices/pay",
    component: ViewPayInvoices

  },
  {
    key: "BuyServices",
    exact: true,
    path: "services/buy",
    component: ViewBuyServices

  },
  {
    key: "Services",
    exact: true,
    path: "/services",
    component: ViewServices

  },
  {
    key: "cookies",
    exact: true,
    path: "/cookies",
    component: ViewCookies
  },
  {
    key: "payments",
    exact: true,
    path: "/payments",
    component: ViewPayments

  },
  {
    key: "messages",
    exact: true,
    path: "/messages",
    component: ViewMessages
  },
  {
    key: "billing",
    exact: true,
    path: "/billing",
    component: ViewBilling

  },
  {
    key: "mydata",
    exact: true,
    path: "/mydata",
    component: ViewCustomerData

  },
  {
    key: "privacyPolicy",
    exact: true,
    path: "/privacy",
    component: ViewPrivacyPolicy
  },
  {
    key: "regulations",
    exact: true,
    path: "/regulations",
    component: ViewRegulations
  }

];
