import React, { Component } from "react";
import { CustApi } from "app/api/CustApi";
import { connect } from "react-redux";
import "assets/css/dashboard-messages-badge.css";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import CustomerInfo from "app/app-components/dashboard/CustomerInfo";
import Contact from "app/app-components/dashboard/Contact";
import CustomerMsg from "app/app-components/dashboard/CustomerMsg";
import BuyService from "app/app-components/dashboard/BuyService";
import Payments from "app/app-components/dashboard/Payments";
import Services from "app/app-components/dashboard/Services";
import { OperApi } from "../../api/OperApi";
import Logger from "../../Logger";


class ViewDashboard extends Component {

  componentDidMount() {

    CustApi.customerServices().then(r => {
      this.props.setCustomerServices(r.data.rows);
    });

    CustApi.customerInfo().then(r => {
      this.props.setCustomerInfo(r.data);
      Logger.debug(r.data);
    });

    CustApi.customerConsents().then(r => {
      this.props.setCustomerConsents(r.data);
    });

    OperApi.getOperatorContact().then(r => {
      this.props.setOperatorInfo(r.data);
    });

    CustApi.customerLastSettlement().then(r => {
        this.props.setCustomerPayment(r.data);
      },
      (error) => console.error(error));
  }

  render() {
    return <div className="container bgr-grey pt-3 pb-3">
      <div className="row">

        <div className="col col-md-6 col-lg-3 d-none d-md-block">
          <div className="row h-100">
            <div className="col">
              <CustomerInfo />
            </div>
          </div>
        </div>

        <div className="col col-12 col-md-6 col-lg-9">
          <div className="row">
            <div className="col col-12 col-xl-8 h-100">
              <Payments />
            </div>


            <div className="col col-12 col-xl-4 mt-4 mt-xl-0 h-xl-100">
              <Contact />
            </div>
          </div>
          <div className="row">

            <div className="col col-12 col-xl-12 mt-4 order-3 order-xl-1">
              <Services />
            </div>

          </div>
          <div className="row">
            <div className="col box02 col-12 col-xl-8 col-lg-6 col-xl-4 mt-4 order-xl-2">
              <CustomerMsg />
            </div>

            <div className="col col-12 col-lg-6 col-xl-4 mt-4 order-xl-3">
              <BuyService />
            </div>
          </div>
          {/*Ukrycie bannerów - brak w IWKD*/}
          {/*<div className="row mt-4 d-none d-lg-block">*/}
          {/*    <Banner/>*/}
          {/*</div>*/}

        </div>
      </div>

      {/*<div className="row mt-4 d-block d-lg-none">*/}
      {/*    <Banner/>*/}
      {/*</div>*/}

    </div>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewDashboard);
