import T from "app/i18n/T";
import * as PropTypes from "prop-types";
import React from "react";
import {CustomInput, FormFeedback, FormGroup} from "reactstrap";
import {NavLink} from "react-router-dom";
import {getMsg, isValid} from "app/app-components/common/Validation";


export function RegisterButton(props) {
    return <div>
        {/*[SORT5-9132] ukrycie checkbox'a w  registration.css
            + defaultChecked={true}*/}
        <FormGroup className="register-statute-checkbox">
            <CustomInput
                invalid={isValid("rulesCheckbox")}
                defaultChecked={true}
                onChange={props.onChange}
                type="checkbox"
                id="rulesCheckbox"
                className="d-inline">
                Akceptuje&nbsp;
                <NavLink exact to="/regulations" target="_blank">regulamin aplikacji eBOK</NavLink>
                <FormFeedback>{getMsg("rulesCheckbox")}</FormFeedback>
            </CustomInput>

        </FormGroup>
        <button disabled={!props.registerPossible}
                className="btn btn-primary btn-block btn-lg btn-signin background-main mt-3"
                onClick={props.onClick}
        >{T("SIGN UP")}
        </button>
    </div>;
}

RegisterButton.propTypes = {
    registerPossible: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func
};
