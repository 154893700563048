import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {FormFeedback, FormGroup, Input} from "reactstrap";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {SecApi} from "app/api/SecApi";
import ActionConfirmation from "app/app-components/common/ActionConfirmation";
import T from "app/i18n/T";
import {getMsg, isValid} from "app/app-components/common/Validation";


class ViewForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            registerSuccess: null,
            message: ""
        }
    }

    reset = (event) => {
        event.preventDefault();
        const data = {email: event.target.email.value};
        SecApi.resetPassword(data).then((r) => {
            if (r.meta.status === "SUCCESS") {
                this.setState({
                    registerSuccess: true,
                    title: "Message was sent",
                    message: 'A link to change the password has been sent to your email address.',
                    loginButton: false
                })
            }

            this.props.setValidation(r.meta.validation);
        });
    };

    render() {

        if (this.state.registerSuccess !== null) {
            return <ActionConfirmation success={this.state.registerSuccess}
                                       message={this.state.message}
                                       title={this.state.title}
                                       loginButton={this.state.loginButton}/>
        } else {
            return <>
                <div className="row d-flex justify-content-center">
                    <div className="col col-12 col-sm-8 col-lg-6 col-xl-4">
                        <div>

                            <p className="ebok-color profile-name-card">
                                {T('Reset password')}
                            </p>

                            <form className="form-signin mt-4" onSubmit={this.reset}>
                                <span
                                    className="reauth-email">{T('Link to change your password will be sent to your email address.')}</span>
                                <FormGroup>
                                    <Input
                                        className="form-control login-box"
                                        id="email" name="email" required=""
                                        placeholder={T('E-mail address')} autoFocus=""
                                        invalid={isValid("email")}>
                                    </Input>
                                    <FormFeedback>{getMsg("email")}</FormFeedback>
                                </FormGroup>

                                <div className="pt-2 pb-3 justify-content-end d-flex"/>

                                <button className="btn btn-primary btn-block btn-lg btn-signin background-main"
                                        type="submit" onClick={this.submit}>
                                    {T('CHANGE PASSWORD')}
                                </button>

                                <div className="pt-2 pb-3 justify-content-end d-flex">
                                    <div className="pt-2 pb-3 justify-content-end d-flex">
                                        <NavLink exact to="/">
                                    <span className="forgot-password pointer">
                                        {T("Back to login form")}
                                    </span>
                                        </NavLink>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </>
        }

    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewForgotPassword);
