import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "serviceWorker";
import { store } from "app/redux/redux";
import { Provider } from "react-redux";
import App from "app/app-main/App";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/Google-Style-Login.css";
import "assets/css/login.css";
import "assets/css/styles.css";
import "assets/css/top-nav-ebok.css";
import "assets/css/all.min.css";
import "assets/css/ebok.css";
import "react-widgets/dist/css/react-widgets.css";
import "assets/css/footer.css";
import localizationPl from "moment/locale/pl";

Moment.updateLocale("pl", localizationPl);
momentLocalizer();

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,

  document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
