import qs from "query-string";
import { ajaxStart, ajaxStop, clearUser, store } from "app/redux/redux";
import T from "app/i18n/T";
import Logger from "app/Logger";
import { setUnavailable, setValidation } from "../redux/redux";

export class Api {

  static apiUrl = "";

  constructor(promise, ignoreError = false) {
    this.p = promise;
    this.ignnoreError = ignoreError;
    this.store = {};
  }

  static fetch(url, options) {
    store.dispatch(ajaxStart());
    return new this(fetch(this.apiUrl + url, options));
  }

  static get(url, local = false) {
    store.dispatch(ajaxStart());
    Logger.debug(`GET [${url}]`);
    return new this(fetch((local ? "http://localhost:3000/api" : this.apiUrl) + url, { credentials: "include" }));
  }

  static getCanError(url, local = false) {
    store.dispatch(ajaxStart());
    Logger.debug(`GET [${url}]`);
    return new this(fetch((local ? "http://localhost:3000/api" : this.apiUrl) + url, { credentials: "include" }), true);
  }


  static delete(url) {
    store.dispatch(ajaxStart());
    Logger.debug(`DELETE [${url}]`);
    return new this(fetch(this.apiUrl + url, { method: "DELETE", credentials: "include" }));
  }

  static post(url, payload) {
    store.dispatch(ajaxStart());
    Logger.debug(`POST [${url}] payload:`, payload);
    return new this(fetch(this.apiUrl + url, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }));
  }

  static put(url, payload) {
    store.dispatch(ajaxStart());
    Logger.debug(`PUT [${url}] payload:`, payload);
    return new this(fetch(this.apiUrl + url, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }));
  }

  static postForm(url, payload) {
    store.dispatch(ajaxStart());
    Logger.debug(`POST [${url}] payload:`, payload);
    return new this(fetch(this.apiUrl + url, {
      credentials: "include",
      method: "POST",
      body: qs.stringify(payload),
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8"
      }
    }));
  }

  static sendFile(url, file) {
    let formData = new FormData();
    formData.append("file", file);
    store.dispatch(ajaxStart());
    Logger.debug(`POST [${url}] payload: file`);
    return new this(fetch(this.apiUrl + url, {
      credentials: "include",
      method: "POST",
      body: formData
    }));
  }

  then(callback, errHandler) {
    this.p
      .then(response => {
        store.dispatch(ajaxStop());
        if (!response.ok) {
          let error = Error(response.status);
          error.status = response.status;
          error.response = response;
          throw error;
        }
        Logger.debug("Fetch OK:", response.url);
        return response;
      })
      .then(response => response.json())
      .then(json => {
        Logger.debug("Fetch OK:", json);
        json.meta.messages.forEach(m => {
          //store.dispatch(pushToast(m.type.substring(0, 1).toLowerCase(), T('Server info: ') + m.text));
        });
        return callback(json);
      })
      .catch(error => {

        if (error.status === 403) {
          store.dispatch(setValidation([
            { field: "username", msg: T("Wrong email or password") }
          ]));
          error.message = "Błędny e-mail lub hasło!";
          store.dispatch(clearUser());
          return;
        }

        if (error.status === 401) {
          store.dispatch(clearUser());
        }
        if (!this.ignnoreError) {
          if (error.status === 404) {
            // store.dispatch(pushToast('e', T('Error: 404 - not found')));
          }


          if (error.status === 500) {
            error.response.json().then((r) => {
              Logger.debug(r);
              store.dispatch(setUnavailable());
            });
          }
        }
        errHandler && errHandler(error.message);

        Logger.error("Fetch error:", error);
      });
  }

}
