import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import T from "app/i18n/T";
import Utils from "app/Utils";

export class ServiceCardNew extends Component {

  prepareAddress = (location) => {
    if (!location) {
      return <>{T("No address")}</>;
    } else {
      return <>{location.street} {location.number}
        <br /> {location.postCode} {location.place} </>;
    }
  };

  getPrice = (service) => {
    const currentBillingCycle = service.pricesInBillingPeriods.find(
      (period) => period.billingCycle === "CURRENT");
    return currentBillingCycle ? currentBillingCycle.price : null;
  };

  render() {
    let product = this.props.item;
    if (this.props.hidden) {
      return "";
    }
    return <>
      <div
        className={"col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-3 "}>
        <div
          className={"service-card shadow-sm d-flex flex-column"}>
          <div className="pt-3 pb-3 pl-3">
            <p className="ebok-color txt-violet-small mb-0">{product.service.name}<br />
            </p>
          </div>
          <div>
            {this.serviceCardRow(T("Service price"),
              <strong>{Utils.addPrecision(this.getPrice(product))} zł
              </strong>,
            )}
            {this.serviceCardRow(T("End date"), product.validUntil ? Utils.formatDate(product.validUntil) : T("Undefined"))}
            {this.serviceCardRow(T("Installation address"), this.prepareAddress(this.props.address ? this.props.address : null))}
          </div>
        </div>
      </div>
    </>;
  }

  serviceCardRow(title, value) {
    return <div className="row serviceDetails">
      <div className="col col-6">
        <p className="txt-black-medium mb-0"><strong>{title}:</strong><br /></p>
      </div>
      <div className="col d-flex justify-content-end justify-content-lg-start">
        <p className="txt-black-small mb-0 txt-l-r">{value}
        </p>
      </div>
    </div>;
  }

}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ServiceCardNew);
