import React, { Component } from "react";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { connect } from "react-redux";
import { contactTypesLang } from "app/i18n/ContactTypesLang";
import Input from "reactstrap/lib/Input";
import {
  Button,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Form } from "react-bootstrap";
import { CustApi } from "app/api/CustApi";
import { getMsg, isValid } from "../common/Validation";
import T from "../../i18n/T";
import { withRouter } from "react-router-dom";

class CustomerContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      form: {
        phone: "", email: ""
      },
      some: "",
      changePasswordModal: false
    };
  }

  componentDidMount() {
    this.setStateFromProps();
  }

  setStateFromProps = () => {
    let customer = this.props.dashboard.customerInfo;
    if (customer) {
      /*let coresp = customer.correspondenceData;*/
      let phone = customer.contactData.find((method) => {
        return method.type === "PHONE";
      });
      let mail = customer.contactData.find((method) => {
        return method.type === "EMAIL";
      });
      this.setState({
        form: {
          /*                    forename: coresp.personalData.forename,
                              surname: coresp.personalData.surname,
                              company: coresp.personalData.companyName,
                              street: coresp.address.street,
                              number: coresp.address.number,
                              place: coresp.address.place,
                              postcode: coresp.address.postCode,
                              post: coresp.address.post,*/
          phone: (phone && phone.value) ? phone.value : "", email: (mail && mail.value) ? mail.value : ""
        }
      });
    }

  };

  onFormChange = (e) => {
    e.persist();
    this.setState(preState => ({
      ...preState, form: {
        ...preState.form, [e.target.name.toLowerCase()]: e.target.value
      }
    }));
  };


  sendForm = (e) => {
    e.preventDefault();
    CustApi.sendCustomerInfo(this.state.form).then(r => {
      this.validateResponse(r);
    });
  };

  validateResponse = (r) => {

    if (r.meta.status === "SUCCESS") {
      this.props.setCustomerInfo(r.data);
      this.setState({ editing: false });
      this.setStateFromProps();
    }

    this.props.setValidation(r.meta.validation);
  };


  /*    inputField(name, prepend) {
          return (<>
              <InputGroup className="pt-2">
                  <InputGroupAddon addonType="prepend"> <InputGroupText>{prepend} </InputGroupText> </InputGroupAddon>
                  <Input type="text" name={name} onChange={this.onFormChange}
                         defaultValue={this.state.form[name]} invalid={isValid(name)}/>
                  <FormFeedback className="changeDataFormInvalid">{getMsg(name)}</FormFeedback>
              </InputGroup>
          </>);
      }*/
  redirectToChangePassword = () => {
    this.setState({ changePasswordModal: false });
    this.props.clearUser();
    this.props.history.push("/forgotpassword");
  };

  render() {

    let customerInfo = this.props.dashboard.customerInfo;
    let contactMethodLang = contactTypesLang();

    if (!customerInfo) {
      return <></>;
    }

    let passwordField = () => {
      return (<div className="row pb-3">
        <div className="col col-sm-6 col-xl-4 mb-2 mb-sm-0">
          <p className="txt-black-medium mb-0">
            <strong>
              {T("Password")}:
            </strong><br />
          </p>
        </div>
        <div className="col pl-3 pl-sm-0">
            <span
              className="d-flex pointer align-items-center text-decoration-none justify-content-start"
              onClick={() => {
                this.setState({ changePasswordModal: true });
              }}>
                <i className="far fa-key ebok-color" />
                <p className="txt-violet-small ebok-color mb-0 pl-2">{T("Change password")} <br /></p>
            </span>
        </div>
        <Modal isOpen={this.state.changePasswordModal} toggle={() => this.setState({ changePasswordModal: false })}
               modalTransition={{ timeout: 500 }} centered>
          <ModalHeader
            toggle={() => this.setState({ changePasswordModal: false })}>
            <p className="txt-violet-big ebok-color mb-0">{T("Change password")}</p>
          </ModalHeader>
          <ModalBody>
            <p className="txt-black-small mb-0">
              {T("You will be taken to the password reset form")}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.redirectToChangePassword()}>{T("Confirm")}</Button>
            <Button color="secondary" onClick={() => this.setState({ changePasswordModal: false })}>{T("Cancel")}</Button>
          </ModalFooter>
        </Modal>
      </div>);
    };

    let corespondentAddress = () => {
      /*            return this.state.editing ?
                      <div className="col col-12 pl-3 pl-sm-0">
                          <div className="row pl-4 pr-2">
                              {this.inputField("forename", "Imie")}
                              {this.inputField("surname", "Nazwisko")}
                              {customerInfo.type === "BUSINESS" && this.inputField("company", "Nazwa firmy")}
                              {this.inputField("street", "Ulica")}
                              {this.inputField("number", "Nr budynku/lokalu")}
                              {this.inputField("place", "Miasto")}
                              {this.inputField("postcode", "Kod pocztowy")}
                              {this.inputField("post", "Poczta")}
                          </div>
                      </div>
                      :*/
      return <div className="col pl-3 pl-sm-0">
        <p className="txt-black-small mb-0 pr-1 pl-3">
          {customerInfo.personalData.forename} {customerInfo.personalData.surname} {customerInfo.type === "BUSINESS" && customerInfo.correspondenceData.personalData.companyName}
          <br />
          {customerInfo.correspondenceData.address.formattedAddress}<br />
        </p>
      </div>;
    };

    let contactMethods = () => {
      return <>
        {this.state.editing ? <>
          {customerInfo.contactData.map((contactMethod, index) => {
            return <div key={index} className="row pb-2">
              <div className="col col-12 pl-3 pl-sm-0">
                <div className="row pl-4 pr-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>{contactMethodLang[contactMethod.type]}:</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" name={contactMethod.type} onChange={this.onFormChange}
                           defaultValue={contactMethod.value}
                           invalid={isValid(contactMethod.type.toLowerCase())} />
                    <FormFeedback
                      className="changeDataFormInvalid">{getMsg(contactMethod.type.toLowerCase())}</FormFeedback>
                  </InputGroup>
                </div>
              </div>
            </div>;
          })}
        </> : customerInfo.contactData.map((contactMethod, index) => {
          return <div key={index} className="row pb-3">
            <div className="col col-sm-6 col-xl-4 mb-2 mb-sm-0">
              <p className="txt-black-medium mb-0">
                <strong>
                  {contactMethodLang[contactMethod.type]}:
                </strong><br />
              </p>
            </div>
            <div className="col pl-3 pl-sm-0">
              <p className="txt-black-small mb-0 pr-1 pl-3">
                {contactMethod.value}<br />
              </p>
            </div>
          </div>;
        })}

      </>;
    };

    return <div className="bgr-w px-4 pb-4 mt-4 mt-md-0" style={{ height: "auto" }}>
      <div className="pt-3 pb-3">
        <p className="txt-violet-big ebok-color mb-0">
          {T("Contact data")}
        </p>
      </div>
      <Form onSubmit={this.sendForm}>
        <div className="row pb-3">
          <div className="col col-12 col-sm-6 col-xl-4 mb-2 mb-sm-0">
            <p className="txt-black-medium mb-0"><strong>{T("Correspondence Address")}:</strong><br />
            </p>
          </div>
          <div>{corespondentAddress()}</div>
        </div>
        <p className="txt-black-medium mb-0 pt-3 pb-2"><strong>{T("Contact methods")}:</strong><br /></p>
        {contactMethods()}
        {passwordField()}
        <div className="row">
          <div className="col col-12">
            {this.state.editing ? <span
                className="d-flex pt-3 pointer align-items-center text-decoration-none justify-content-end">
                            <Button color="success" className="mr-2">{T("Save")}</Button>
                            <Button color="danger" onClick={() => {
                              this.setState({ editing: false });
                            }}> {T("Cancel")} </Button>
                            </span> :
              <span className="d-flex pointer align-items-center text-decoration-none justify-content-end"
                    onClick={() => {
                      this.setState({ editing: true });
                    }}>
                            <i className="far fa-edit ebok-color" />
                            <p className="txt-violet-small ebok-color mb-0 pl-2">{T("Edit")} <br /></p>
                        </span>}
          </div>
        </div>
      </Form>
    </div>;
  }

}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(CustomerContact));
