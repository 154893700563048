import T from "app/i18n/T";
import React from "react";
import {FormFeedback, FormGroup, Input} from "reactstrap";
import {getMsg, isValid} from "app/app-components/common/Validation";

export const RegistrationStep1 = props => {
    return <div
        className="col step-box-stripe-v1 col-12 col-md-6 col-xl-4 px-3 px-lg-5 mt-3 pb-4">

        <div className="d-lg-flex align-items-lg-start h-25">
            <div className="row">
                <div className="col col-12">
                    <div className="icon-w-text">
                        <div>
                            <p className="circle-number">1</p>
                            <i className="fas fa-lg fa-circle ebok-color"/>
                        </div>
                        <div>
                            <p className="step-txt pl-2">
                                {T("Provide us with client number and token from your agreement")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <FormGroup>
            <Input id="crmNumber" type="text" onChange={props.onChange}
                   className="w-100 txt-input form-control login-box"
                   value={props.values.crmNumber}
                   placeholder={T("Client number")} invalid={isValid("crmNumber")}/>
            <FormFeedback>{getMsg("crmNumber")}</FormFeedback>
        </FormGroup>

        <FormGroup>
            <Input id="crmToken" type="text" onChange={props.onChange}
                   className="w-100 txt-input form-control login-box"
                   value={props.values.crmToken}
                   placeholder={T("Token")} invalid={isValid("crmToken")}/>
            <FormFeedback>{getMsg("crmToken")}</FormFeedback>
        </FormGroup>

    </div>
};