export default class Logger {

    static LEVEL = {
        DEBUG: 4,
        INFO: 3,
        WARNING: 2,
        ERROR: 1
    };

    static debug(...msg) {
        if (this.LEVEL.DEBUG <= (window.var.LOG_LEVEL || 1))
            console.debug(...msg)
    }

    static info(...msg) {
        if (this.LEVEL.INFO <= (window.var.LOG_LEVEL || 1))
            Logger.debug(...msg)
    }

    static warning(...msg) {
        if (this.LEVEL.WARNING <= (window.var.LOG_LEVEL || 1))
            console.warn(...msg)
    }

    static error(...msg) {
        if (this.LEVEL.ERROR <= (window.var.LOG_LEVEL || 1))
            console.error(...msg)
    }

}