import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import React, { Component } from "react";
import { CustApi } from "app/api/CustApi";
import T from "app/i18n/T";
import Chart from "react-apexcharts";
import _ from "lodash";
import { Button, ButtonGroup } from "reactstrap";
import { langs } from "app/i18n/i18n";
import Utils from "../../Utils";

class PaymentsChart extends Component {

    constructor(props) {
        super(props);
        this.chart = React.createRef();

        let locales = langs.map(l => ({
            name: l.key,
            options: {
                toolbar: {
                    exportToSVG: T('Download SVG', l.key),
                    exportToPNG: T('Download PNG', l.key),
                    exportToCSV: T('Download CSV', l.key)
                }
            }
        }));


        this.state = {
            loaded: false,
            active: "Q",
            chart: {
                options:
                    {
                        noData:{
                            text: T('No Data')
                        },
                        chart: {
                            height: 350,
                            type: 'bar',
                            stacked: true,
                            defaultLocale: this.props.lang,
                            locales: locales
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    position: 'center',
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            offsetY: 5,
                            style: {
                                fontSize: '12px',
                            },
                            formatter: function (val) {
                                return Utils.addPrecision(val) + " zł";
                            },
                        },

                        xaxis: {
                            categories: [],
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            crosshairs: {
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        colorFrom: '#D8E3F0',
                                        colorTo: '#BED1E6',
                                        stops: [0, 100],
                                        opacityFrom: 0.4,
                                        opacityTo: 0.5,
                                    }
                                }
                            },

                        },
                        colors: ["#15ADAA", "#D86152"],
                        fill: {
                            gradient: {
                                type: "horizontal",
                                shadeIntensity: 0.25,
                                gradientToColors: undefined,
                                inverseColors: true,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [50, 0, 100, 100]
                            },
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                        },
                        tooltip: {
                            x: {
                                formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                                    return " wartość faktury: " + Utils.addPrecision(_.sum(_.map(series, s => s[dataPointIndex]))) + " zł"}
                            }
                        }
                    },
                series: []
            }
        };
    }


    isActive(key) {
        return this.state.active === key;
    }

    setActive(key) {
        this.setState({active: key, loaded: false});
        this.loadData(key);
    }

    componentDidMount() {
        this.loadData("Q");
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.lang !== prevProps.lang) {
            this.chart.current.chart.setLocale(this.props.lang);
            this.loadData();

        }
    }


    loadData(range) {
        CustApi.customerPayments(range).then(r => {

            let newState = {};
            _.extend(newState, this.state);
            newState.chart.options.noData.text = T("No Data");
            newState.chart.options.xaxis.categories = r.data.map(item => item.invoice);
            if (!_.isEmpty(r.data)) {
                newState.chart.series = [
                    {
                        name: T('paid'),
                        data: r.data.map(item => item.amount)
                    },
                    {
                        name: T('to paid'),
                        data: r.data.map(item => item.remaining)
                    }
                ];
            }
            newState.loaded = true;
            this.setState(newState);

        });
    }

    render() {
        if (this.state.loaded && this.props.lang) {
            return <>
                <span className="ebok-color txt-violet-big mb-0">{T('Payment history')}


                    <ButtonGroup size="sm" style={{right: 30, position: 'absolute'}}>
                        <Button color="primary" active={this.isActive("Q")}
                                onClick={() => this.setActive("Q")}>
                            {T('Last quarter')}
                        </Button>
                        <Button color="primary" active={this.isActive("H")}
                                onClick={() => this.setActive("H")}>
                            {T('Last six months')}
                        </Button>
                        <Button color="primary" active={this.isActive("Y")}
                                onClick={() => this.setActive("Y")}>
                            {T('Last year')}
                        </Button>

                    </ButtonGroup>
                </span>
                <div className="mixed-chart">
                    <Chart
                        ref={this.chart}
                        options={this.state.chart.options}
                        series={this.state.chart.series}
                        height={370}
                        type="bar"
                    />
                </div>
            </>
        } else {
            return null
        }
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(PaymentsChart);
