import T from "./T";


export const dataTableLang = () => {
    return {
        pageSize: T('Page size'),
        page: T('Page'),
        of: T('of'),
        showFilters: T('Show filters'),
        hideFilters: T('Hide filters'),
        clear: T('Clear'),
        search: T('Search')
    }
};