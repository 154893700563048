import moment from "moment";
import {Progress} from "reactstrap";
import {SecApi} from "app/api/SecApi";
import React, {Component} from "react";

export default class TokenButton extends Component {


    constructor(props) {
        super(props);
        this.state = {
            consultantToken: null,
            tokenValidTo: null,
            timerValue: 100,
            timerText: ""
        }
    }

    countdown = () => {
        this.setState({
            timeout: setInterval(() => {
                this.updateTimerValues()
            }, 1000)
        })
    };

    componentWillUnmount() {
        this.setState({
            timeout: clearInterval(this.state.timeout)
        });
    }

    action = () => {
        SecApi.generateToken().then((r) => {
            this.setState({
                consultantToken: r.data.token,
                tokenValidTo: r.data.validTo
            }, () => {
                this.updateTimerValues();
            })
        });
        this.countdown()
    };


    updateTimerValues() {
        let remaining = moment(this.state.tokenValidTo).diff(moment(), 'seconds');

        if (remaining < 0) {
            this.setState({
                consultantToken: null
            })
        }


        this.setState({
            timerValue: moment(this.state.tokenValidTo).diff(moment(), 'seconds') / 3600 * 100,
            timerText: (Math.floor(remaining / 60)) + " : " + ((remaining % 60) < 10 ? "0" + (remaining % 60) : (remaining % 60))
        })
    }

    render() {

        return this.state.consultantToken ?
            <>
                <p className="txt-black-small border border-success mt-3 py-2">{this.state.consultantToken}</p>
                <Progress
                    value={this.state.timerValue}>{this.state.timerText}
                </Progress>
            </>
            :
            <button className="btn btn-primary btn-block btn-lg btn-signin-thin background-main" onClick={this.action}>
            TOKEN
            </button>

    }
}