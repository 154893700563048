import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import T from "../../i18n/T";
import _ from "lodash";

class CustomerInvoiceType extends Component {

  render() {
    if (!this.props.customerData.customerConsents && !_.isEmpty(this.props.customerData.customerConsents.rows.filter(it => (it.dictionaryConsent.identifier === "ZGODA_EFAKTURA")))) {
      return null;
    }
    let einvoice = this.props.customerData.customerConsents.rows.filter(it => (it.dictionaryConsent.identifier === "ZGODA_EFAKTURA"))[0].consentUsage.state === "ACQUIRED";
    return <div className="bgr-w px-4 pb-4 mt-4" style={{ height: "auto" }}>
      <div className="pt-3 pb-3 pl-2">
        <p className="txt-violet-big ebok-color mb-0">
          {T("Invoice type")}
        </p>
        <div className="row pt-4 align-items-center">
          <div className="col col-2 col-lg-1">
            <i className="fal fa-2x fa-file-invoice text-success" />
          </div>

          <div className="col col-10 col-lg-11">
            <p className="d-inline txt-black-medium mb-0">
              <strong>{T("Default invoice type")}: </strong>
            </p>
            <span>
                                {einvoice ? T("E-Invoice") : T("Standard Invoice")}
                        </span>
          </div>
          <div className="col col-12">

          </div>

        </div>

      </div>
    </div>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(CustomerInvoiceType);
