import {Api} from "./Api";

export class DevApi extends Api {

	static apiUrl = (window.location.port === '3000' && window.var.DEVAPI_URL) || '/dev-api';

    static clearCacheForCurrentUser() {
        return DevApi.get('/dev/clear/current')
    }

    static clearAllCache() {
        return DevApi.get('/dev/clean')
    }

}
