import React, {Component} from "react";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {connect} from "react-redux";
import {Button, UncontrolledTooltip} from "reactstrap";
import {CustApi} from "app/api/CustApi";
import _ from "lodash"
import T from "../../i18n/T";

class CustomerConsents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            editConsent: null
        }
    }


    printConsentStatusIcon(consentUsage) {
        switch (consentUsage.state) {
            case "ACQUIRED":
                return <i className="fal fa-2x fa-check-circle text-success"/>;
            case "CANCELLED":
                return <i className="fal fa-2x fa-times-circle text-danger"/>;
            default:
                return <i className="fal fa-2x fa-question-circle text-warning"/>;
        }
    }

    sendConsent = (consent, value) => {
        CustApi.sendConsent({
            dictionaryConsent: {
                identifier: consent.dictionaryConsent.identifier,
                name: consent.dictionaryConsent.name
            },
            consentUsage: {
                usagePresence: "NO_LIMBO",
                state: value
            }
        }).then(r => {

            if (r.meta.status === "SUCCESS"){
                this.props.setCustomerConsents(r.data);
                this.setState({
                    editing: false,
                    editConsent: null
                });
                CustApi.customerInfo().then(r => {
                    this.props.setCustomerInfo(r.data);
                })
            }
        })
    };

    consentButtons = (consent) => {
        switch (consent.consentUsage.state) {
            case "ACQUIRED":
                return <div className="pt-3">
                    <Button onClick={() => this.sendConsent(consent, "CANCELLED")}
                            color="danger"
                            className="mt-2 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-0">
                        {T('I do not agree')}
                    </Button>
                </div>;
            case "CANCELLED":
                return <div className="pt-3">
                    <Button onClick={() => this.sendConsent(consent, "ACQUIRED")}
                            color="success"
                            className="mr-2">
                        {T('I agree')}
                    </Button>
                </div>;
            default:
                return <div className="pt-3">
                    <Button onClick={() => this.sendConsent(consent, "ACQUIRED")}
                            color="success"
                            className="mr-2"> {T('I agree')} </Button>
                    <Button onClick={() => this.sendConsent(consent, "CANCELLED")}
                            color="danger"
                            className="mt-2 mt-sm-0 mt-md-0 mt-lg-2 mt-xl-0"> {T('I do not agree')} </Button>
                </div>

        }
    };

    consentCollapse = (consent) => {
        let consentId = consent.dictionaryConsent.identifier;
        if (this.state.editConsent === consentId && this.state.editing) {
            this.setState({editing: false, editConsent: ""})
        } else {
            this.setState({editing: true, editConsent: consentId})
        }
    };

    render() {
        let consents = this.props.customerData.customerConsents;
        let rows = consents ? _.sortBy(consents.rows, (row) => {
            return row.dictionaryConsent.order
        }) : "";

        if (!rows) {
            return <></>
        }

        return consents !== undefined ? <div className="bgr-w px-4 pb-4 mt-4 pl-4" style={{height: "auto"}}>
            <div className="pt-3 pb-3">
                <p className="txt-violet-big ebok-color mb-0">{T('Customer consents')}</p>
            </div>
            {rows.map(consent => {
                return <div key={consent.dictionaryConsent.name} className="mx-1 bottom-border-grey-2">
                    <div className="row pb-4 pt-4">
                        <div className="col col-2 col-lg-1">
                            {this.printConsentStatusIcon(consent.consentUsage)}
                        </div>
                        <div className="col col-9">
                            <p className="txt-black-medium mb-2">
                                <strong>
                                    {consent.dictionaryConsent.name}&nbsp;
                                    {consent.dictionaryConsent.required &&
                                    <>
                                        <i className="far fa-exclamation-circle text-muted" id="required-field"/>
                                        <UncontrolledTooltip placement="right" target="required-field">
                                            {T('This field is required')}
                                        </UncontrolledTooltip></>}
                                </strong><br/>
                            </p>
                            <p className="txt-black-small mb-0 pr-1">
                                {consent.dictionaryConsent.content}
                                <br/>
                            </p>
                            {(this.state.editing === true && this.state.editConsent === consent.dictionaryConsent.identifier) ?
                                this.consentButtons(consent)
                                : ""}
                        </div>

                        <div className="col pt-4 pt-sm-0 pt-md-4 pt-lg-0 pl-0 col-12 col-md-12 col-xl-2">
                            {consent.dictionaryConsent.required ||
                            <span
                                className=" pointer d-flex align-items-center text-decoration-none justify-content-end"
                                onClick={() => this.consentCollapse(consent)}>
                                <i className="far fa-edit ebok-color"/>
                                <p className="txt-violet-small ebok-color mb-0 pl-2">
                                    {T('Change')}<br/>
                                </p>
                            </span>}

                        </div>
                    </div>
                </div>
            })}

        </div> : "";
    }

}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(CustomerConsents);