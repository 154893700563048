import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import * as qs from "query-string";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {SecApi} from "app/api/SecApi";
import ChangePasswordForm from "app/app-components/changePassword/ChangePasswordForm";
import ActionConfirmation from "../../app-components/common/ActionConfirmation";


export class ViewChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            showForm: false,
            showAlert: false,
            alertSuccess: false,
            alertTitle: '',
            alertMessage: ''
        };

        this.updateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.setState({
                token: qs.parse(this.props.location.search, {ignoreQueryPrefix: true})["token"]
            }, () => {
                SecApi.resetPasswordCheckToken(this.state.token).then((json) => {
                    let errMessages = json.meta.messages.filter((message => {
                        return message.type === "ERROR"
                    }));
                    if (errMessages.length === 0) {
                        this.setState({
                            showForm: true,
                            showAlert: false
                        })
                    } else {
                        this.setState({
                            showForm: false,
                            showAlert: true,
                            alertSuccess: false,
                            alertTitle: 'Twój token wygasł, lub aktywacja się nie powiodła',
                            alertMessage: errMessages[0].text
                        })
                    }
                })
            }
        )
    }

    updateState(update) {
        this.setState(update)
    }

    render() {
        if (this.state.showForm) {
            return <ChangePasswordForm update={this.updateState} token={this.state.token}/>
        } else if (this.state.showAlert) {
            return <ActionConfirmation success={this.state.alertSuccess}
                                       title={this.state.alertTitle}
                                       message={this.state.alertMessage}
                                       loginButton={true}/>
        } else {
            return <></>
        }
    }
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(ViewChangePassword));
