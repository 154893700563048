import React, { Component } from "react";
import T from "app/i18n/T";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import Utils from "app/Utils";
import { CustApi } from "app/api/CustApi";
import { dataTableLang } from "app/i18n/DataTableLang";
import DataTable from "app/app-components/common/DataTable/DataTable";

class Services extends Component {

  render() {
    return <div className="bgr-w h-100 px-4 pb-1">
      <div className="pt-3 pb-3">
        <p className="ebok-color txt-violet-big mb-0">{T("Your services")}</p>
      </div>
      <DataTable
        id="DashboardServices"
        api={CustApi.customerServices}
        cols={[
          { header: T("Agreement"), field: "contract.number", mobileHeader: true },
          {
            header: T("Services"),
            field: "contract.products",
            formatter: (v) => {
              const sortedProducts = v.sort((a, b) => a.name.localeCompare(b.name));
              return sortedProducts.map((product, i) => (
                <li key={`li-name-${i}`} className="li-without-dot">
                  {Utils.checkIfDateIsGreaterThanToday(product.currentSubscription?.validUntil) ?
                    <i className="far fa-check-circle ebok-color px-1"
                       title={`${T("Active until")}: ${Utils.formatDate(product.currentSubscription.validUntil)}`}
                    /> :
                    <i className="far fa-times-circle red-color px-1"
                       title={T("Inactive")}
                    />
                  }
                  {product.name}</li>
              ));
            },
            tdClassName: "align-left, txt-black-small",
          },
          { header: T("Price"), field: "contract.price", formatter: (v) => Utils.addPrecision(v) + " zł" },
          {
            header: T("Commitment to"),
            field: "contract.minimalProvisionUntil",
            formatter: (v) => {
              return <span style={{ whiteSpace: "nowrap" }}>{Utils.formatDate(v)}</span>;
            },
          },
        ]}
        labels={dataTableLang()}
        pageable={false}
        sortByType="SERVICES"
      />
    </div>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Services);
