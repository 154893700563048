import {Route, withRouter} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import {mapStorageToProps} from "app/redux/mappers";
import {Alert} from "reactstrap";


const AppSecureRoute = ({component: Component, props, ...rest}) => {
    return (
        <div>
            <Route {...rest} render={(props) => {
                return (
                    rest.user
                        ? <Component {...props} />
                        : <Alert color="warning"><i className="fas fa-exclamation-circle"/> Insufficient privileges.
                            Please contact your Administrator.</Alert>
                );
            }}/>
        </div>
    );
};

export default withRouter(connect(mapStorageToProps)(AppSecureRoute));