import React, {Component} from 'react';
import {Button, Card, CardBody, CardFooter, CardText} from "reactstrap";
import "assets/css/message-collapse-fable-fix.css"
import {CustApi} from "app/api/CustApi";
import T from "app/i18n/T";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {PieSpinner} from "app/app-components/messages/PieSpinner";
import {MessageIcon} from "app/app-components/messages/MessagesIcon";
import Utils from "app/Utils";


class ViewMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card: false,
            messages: []
        }
    }

    getMessages = () => {
        CustApi.getAllMessages().then((d) => {
            CustApi.getMessagesCount().then((r) => this.props.setMsgCounter(r.data));
            this.setState({
                messages: d.data
            })
        });
    };

    componentDidMount() {
        this.getMessages()
    }

    createMessageTimeout(message) {
        return (message.status === "READ" || this.state.card === null) ? null : setTimeout(() => {
            CustApi.markMessageRead(message.id).then(this.getMessages);
            this.getMessages()
        }, 5000);
    }


    selectCardAndSetTimeout(message) {
        this.setState({
            read: clearTimeout(this.state.read)
        });
        this.setState({
            card: this.state.card === message.id ? null : message.id,
        }, () => {
            this.setState({
                read: this.createMessageTimeout(message)
            })
        })
    }

    componentWillUnmount() {
        this.setState({
            read: clearTimeout(this.state.read)
        })
    }

    printMessageTitle(message) {
        return message.status === "NEW" ? <strong>{message.title}</strong> : <>{message.title}</>;
    }

    deleteMessage = (id) => {
        CustApi.markMessageDelete(id).then(() => {
            this.getMessages()
        });
    };

    downloadInvoice = (invUid) => {
        return () => {
            CustApi.downloadInvoice(invUid).then(r => {
                Utils.downloadByBlob(r.data.filename, r.data.content, r.data.mediaType);
            });
        }
    };

    printMessages = () => {
        return this.state.messages.map((message) => {
            return <React.Fragment key={message.id}>
                <tr>
                    <td className="text-center">
                        {(this.state.card === message.id && message.status !== "READ") ?
                            <PieSpinner style={{backgroundColor: "inherit"}}/>
                            :
                            <MessageIcon messageType={message.type}/>
                        }
                    </td>
                    <td className={"pointer " + (this.state.card === message.id ? "header-active" : "")}
                        onClick={() => this.selectCardAndSetTimeout(message)}>
                        {this.printMessageTitle(message)}
                    </td>
                    <td className="text-nowrap text-center">{Utils.getFormattedDate(message.createdDt)}</td>
                    <td className="text-nowrap text-center">
                        <Button outline className="btn-outline-primary" color="primary" size="sm"
                                onClick={() => this.deleteMessage(message.id)}>
                            <i className="fa fa-trash"/>
                        </Button>
                    </td>
                </tr>
                <tr className={(this.state.card !== null && this.state.card === message.id) ? "active message-row" : "message-row"}>
                    <td/>
                    <td colSpan={3}>
                        <div>
                            <Card className="mt-2">
                                <CardBody className="w-100">
                                    <CardText style={{whiteSpace: "pre-wrap"}}>{message.content}</CardText>
                                </CardBody>
                                {message.invoice &&
                                <CardFooter>
                                    <span className="ebok-color font-weight-bold pointer"
                                          onClick={this.downloadInvoice(message.invoice)}>
                                    <i className="far fa-file-download fa-lg px-2 ebok-color"/>
                                    Pobierz fakturę
                                    </span>
                                </CardFooter>}
                            </Card>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        })
    };


    render() {
        return <>
            <div className="container bgr-grey pt-3 pb-3">
                <div className="table-responsive bgr-w">
                    <table className="table table-hover message-table">
                        <caption className="ml-2">{T('You have')} <strong>{this.state.messages.filter((message) => {
                            return message.status === "NEW"
                        }).length}</strong> {T('unread messages')}
                        </caption>
                        <thead>
                        <tr>
                            <th className="text-center"/>
                            <th className="text-center">{T('Title of the message')}</th>
                            <th className="text-center">{T('Date')}</th>
                            <th className="text-center"/>
                        </tr>
                        </thead>
                        <tbody>
                        {this.printMessages()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewMessages);