import React, { Component } from "react";
import T from "app/i18n/T";
import { StatusDot } from "app/app-components/common/StatusDot";
import Utils from "app/Utils";
import { CustApi } from "app/api/CustApi";
import DataTable from "app/app-components/common/DataTable/DataTable";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import QRCode from "qrcode.react";

class InvoicesTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      qrOpen: null,
      qrText: "",
    };
  }

  invoiceActions(r) {
    switch (r.documentStatus) {
      case "READY" :
        return <>
                    <span className="pointer">
                        <i className="far fa-file-download fa-lg px-2 ebok-color" title={T("Download invoice")}
                           onClick={this.downloadInvoice(r)} /></span>
        </>;
      case "WAITING" :
        return <>
                    <span className="pointer">
                        <i className="far fa-hand-holding-usd fa-lg px-2 ebok-color" title={T("Order invoice")}
                           onClick={this.orderInvoice(r)} />
                    </span>
        </>;
      case "PROCESSING" :
        return <>
                        <span className="far fa-hourglass-half fa-lg px-2 ebok-color"
                              title={T("Invoice already ordered")} />
        </>;
      default:
        return <></>;
    }
  }

  downloadInvoice = (inv) => {
    return () => {
      CustApi.downloadInvoice(inv.uid).then(r => {
          Utils.downloadByBlob(r.data.filename, r.data.content, r.data.mediaType);
        },
        (error) => this.props.pushToast("e", error));
    };
  };

  orderInvoice = (inv) => {
    return () => {
      CustApi.orderInvoice(inv.uid).then(r => {
          inv.documentStatus = "PROCESSING";
          this.props.pushToast("s", T("The invoice has been ordered"));
        },
        (error) => this.props.pushToast("e", error));
    };
  };

  billingActions(r) {
    if (r.billingInvoice === "WITH_BILLING") {
      switch (r.documentStatus) {
        case "READY" :
          return <>
                    <span className="pointer">
                        <i className="far fa-file-download fa-lg px-2 ebok-color" title={T("Download billing")}
                           onClick={this.downloadInvoiceBilling(r)} /></span>
          </>;
        case "WAITING" :
          return <>
                    <span className="pointer">
                        <i className="far fa-hand-holding-box fa-lg px-2 ebok-color" title={T("Order billing")}
                           onClick={this.orderInvoiceBilling(r)} />
                    </span>
          </>;
        case "PROCESSING" :
          return <>
                        <span className="far fa-hourglass-half fa-lg px-2 ebok-color"
                              title={T("Billing already ordered")} />
          </>;
        default:
          return <></>;
      }

    } else {
      return <></>;
    }
  }

  downloadInvoiceBilling = (inv) => {
    return () => {
      CustApi.downloadInvoiceBilling(inv.uid).then(r => {
          Utils.downloadByBlob(r.data.filename, r.data.content, r.data.mediaType);
        },
        (error) => this.props.pushToast("e", error));
    };
  };

  orderInvoiceBilling = (inv) => {
    return () => {
      CustApi.orderInvoiceBilling(inv.uid).then(r => {
          inv.documentStatus = "PROCESSING";
          this.props.pushToast("s", T("The billing has been ordered"));
        },
        (error) => this.props.pushToast("e", error));
    };
  };


  toggleQr(id, text) {
    this.setState({
      qrOpen: this.state.qrOpen === id ? null : id,
      qrText: text,
    });
  }

  closeQr = () => {
    this.setState({
      qrOpen: null,
      qrText: "",
    });
  };

  qrcode(r) {

    if (r.remainingDebit === 0 || window.innerWidth < 991) {
      return null;
    }

    const id = "qr-" + r.uid;

    const text = [
      this.props.dashboard.operatorInfo.recipientNip,
      "PL",
      r.bankAccountNumber,
      (r.remainingDebit * 100).toFixed(0),
      this.props.dashboard.operatorInfo.recipientName,
      r.number,
      "", "", "",
    ].join("|");

    return <>
      <i id={id} className="far fa-qrcode fa-lg px-2 ebok-color"
         onClick={() => this.toggleQr(id, "aaa")} />

      <Popover placement="left" isOpen={this.state.qrOpen === id} target={id}>
        <PopoverHeader className="d-flex align-items-center pr-2">{T("Pay with QR code")}
          <button type="button" className="close d-flex align-items-center ml-3" onClick={this.closeQr}>&times;</button>
        </PopoverHeader>
        <PopoverBody>

          <div style={{ textAlign: "center" }}>
            <QRCode value={text} size={200} />
          </div>

        </PopoverBody>
      </Popover>

    </>;
  }

  render() {
    return <>
      <div className="row mt-4">
        <div className="col">
          <div className="bgr-w">
            <DataTable id="ViewInvoiceTable"
                       api={CustApi.customerSettlements}
                       cols={[
                         {
                           header: T("Invoice number"),
                           field: "number",
                           tdClassName: "text-center text-nowrap ebok-color txt-violet-small",
                           mobileHeader: true,
                         },
                         {
                           header: T("Invoice value"),
                           field: "grossAmount",
                           formatter: (v) => <>{Utils.addPrecision(v)} zł</>,
                         },
                         { header: T("Issued date"), field: "issueDate", formatter: Utils.formatDate },
                         { header: T("Payment due date"), field: "paymentDueDate", formatter: Utils.formatDate },
                         {
                           header: T("Payment status"), tdClassName: "text-nowrap text-center",
                           formatter: (v, r) => {
                             return <>
                                               <span className="txt-black-small mb-0">
                                                    <StatusDot
                                                      status={r.isPaid} />&nbsp;{r.isPaid ? T("Paid") : T("Not paid")}</span>
                             </>;
                           },
                         },
                         {
                           header: T("Actions"),
                           tdClassName: "text-right",
                           formatter: (v, r) => <>
                             {this.qrcode.bind(this)(r)}
                             {this.billingActions.bind(this)(r)}
                             {this.invoiceActions.bind(this)(r)}
                           </>,
                           mobileHeader: false,
                         },
                       ]}
                       pageable={false}
                       sortByType="INVOICE"
            />
          </div>
        </div>
      </div>


    </>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(InvoicesTable);
