import React from 'react';
import {NavLink} from "react-router-dom";
import LangPicker from "app/i18n/LangPicker";
import T from "app/i18n/T";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import Animista, {AnimistaTypes} from "react-animista";

class AppTopbarAnonymous extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileMenuOpened: false,
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    toggleMobileMenu = () => {
        this.setState((oldState) => ({
            mobileMenuOpened: !oldState.mobileMenuOpened
        }))
    };

    render() {

        const navbar = <div>
            <div className="container nav-container">
                <div className="navbar navbar-expand-lg navbar-light pb-4 pt-4">
                        <span className="navbar-toggler order-1" data-toggle="collapse"
                              data-target="#navbarCollapse">
                            <span className="navbar-toggler-icon"/>
                        </span>

                    <NavLink exact to="/">
                            <img alt="" className="img-fluid logo order-0" src="/logo.png"/>
                    </NavLink>

                    <div id="navbarCollapse"
                         className="collapse navbar-collapse clearfix order-3 order-lg-2 py-3 py-lg-0">
                        <ul className="navbar-nav ml-auto d-flex">

                            <li className="justify-content-start d-flex align-items-center">
                                <NavLink exact to="/help" className="no-pointer">
                                    <span
                                        className="grey-link-ico ml-0 ml-lg-5 mt-1 pointer d-flex align-items-center">
                                        <i className="fal fa-2x fa-question-circle pr-2"/>
                                        {T('Help')}
                                    </span>
                                </NavLink>
                            </li>

                            <li className="justify-content-start d-flex align-items-center">
                                <NavLink exact to="/howToLogin" className="no-pointer">
                                    <span
                                        className="grey-link-ico ml-0 ml-lg-5 mt-1 pointer d-flex align-items-center">
                                        <i className="fal fa-2x fa-key pr-2"/>
                                        {T('How To Log In')}
                                    </span>
                                </NavLink>
                            </li>

                            {/*<li className="justify-content-start d-flex align-items-center">*/}
                            {/*    <NavLink exact to="/chat" className="no-pointer">*/}
                            {/*        <span*/}
                            {/*            className="grey-link-ico ml-0 ml-lg-5 mt-1 pointer d-flex align-items-center">*/}
                            {/*        <i className="fal fa-2x fa-comments pr-2"/>*/}
                            {/*            {T('chat/messenger')}*/}
                            {/*        </span>*/}
                            {/*    </NavLink>*/}
                            {/*</li>*/}

                            <LangPicker globe/>
                        </ul>
                    </div>
                </div>
            </div>
        </div>;

        const navbarMobile = <>
            <div className="align-items-center pt-5">
                <NavLink exact to="/">
                    <img alt="" className="img-fluid logo order-0 mx-auto d-block" src="/logo.png"/>
                </NavLink>
            </div>
            <Animista type={this.state.mobileMenuOpened ? AnimistaTypes.SLIDE_LEFT : AnimistaTypes.SLIDE_RIGHT}
                      tag="div"
                      className="mobile-bgr float-right shadow-sm"
                      style={{marginRight: "-115px", zIndex: "2", height: "100%", overflowY: "scroll"}}
                      duration="10000">
                <div className="mb-4">
                        <span onClick={this.toggleMobileMenu}>
                            <i className="far fa-2x fa-times ebok-color"/>
                        </span>
                </div>
                <ul className="navbar-nav">
                    <li className="mobile-menu-item">
                        <NavLink exact to="/help" className="grey-link-ico d-flex align-items-center">
                            <i className="fal fa-2x fa-question-circle pr-2"/>
                            {T('Help')}
                        </NavLink>
                        <div className="grey-stripe"/>
                    </li>
                    <li className="mobile-menu-item">
                        <NavLink exact to="/howToLogin" className="grey-link-ico d-flex align-items-center">
                            <i className="fal fa-2x fa-key pr-2"/>
                            {T('How To Log In')}
                        </NavLink>
                        <div className="grey-stripe"/>
                    </li>
                    <LangPicker globe style={{paddingTop: "20px"}}/>
                </ul>
            </Animista>
            <div>
                <div style={{position: "fixed", right: 20, top: 20, zIndex: "1"}}>
                    <span onClick={this.toggleMobileMenu}><i className="fas fa-2x fa-bars ebok-color"/></span>
                </div>
            </div>
        </>;

        return this.state.width > 991 ? navbar : navbarMobile
    }


}


export default connect(mapStorageToProps, mapStorageDispatchToProps)(AppTopbarAnonymous);