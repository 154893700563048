import {
    ajaxStart,
    ajaxStop,
    clearUser,
    clearValidation,
    dismissToast,
    pushToast,
    setAvailable,
    setContrast,
    setCustomerConsents,
    setCustomerData,
    setCustomerInfo,
    setCustomerPayment,
    setCustomerServices,
    setDataTable,
    setDataTableParams,
    setFont,
    setLang,
    setMsgCounter,
    setOperatorConfig,
    setOperatorInfo,
    setServices,
    setTheme,
    setUnavailable,
    setUser,
    setValidation
} from "./redux";

export const mapStorageToProps = state => ({
    dashboard: state.dashboard,
    user: state.user,
    lang: state.lang,
    operatorConfig: state.operatorConfig,
    theme: state.theme,
    toast: state.toast,
    loading: state.loading,
    services: state.services.services,
    validation: state.validation,
    msgCounter: state.msgCounter,
    dataTable: state.dataTable,
    customerData: state.customerData,
    appUnavailable: state.unavailable
});

export const mapStorageDispatchToProps = {
    setLang,
    setOperatorConfig,
    setTheme,
    setUser,
    clearUser,
    pushToast,
    dismissToast,
    ajaxStart,
    ajaxStop,
    setCustomerInfo,
    setOperatorInfo,
    setCustomerPayment,
    setCustomerServices,
    setServices,
    setValidation,
    clearValidation,
    setMsgCounter,
    setDataTable,
    setDataTableParams,
    setCustomerData,
    setCustomerConsents,
    setFont,
    setContrast,
    setUnavailable,
    setAvailable
};
