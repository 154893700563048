import React, {Component} from "react";
import T from "app/i18n/T";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {NavLink} from "react-router-dom";
import TokenButton from "app/app-components/dashboard/TokenButton";
import round_logo from "assets/img/round-log.svg"

class CustomerInfo extends Component {

    getContactData = (type) => {
        return this.props.dashboard.customerInfo.contactData.filter((contact) => {
            return contact.type === type
        }).map((contact) => {
            return contact.value
        })
    };

    getCustomerContact(type, classNames) {
        return this.getContactData(type).map(
            (contact) => {
                return <p key={contact} className={classNames}>{contact}</p>
            });
    }

    render() {

        if (!this.props.dashboard.operatorInfo || !this.props.dashboard.customerInfo || !this.props.customerData.customerConsents) {
            return null;
        }

        let ci = this.props.dashboard.customerInfo;
        let oi = this.props.dashboard.operatorInfo;

        let einvoice = this.props.customerData.customerConsents.rows.filter(it => (it.dictionaryConsent.identifier === "ZGODA_EFAKTURA"))[0].consentUsage.state === "ACQUIRED";

        return <div className="bgr-w px-4 pb-4">
            <div className="justify-content-center d-flex pt-4 mb-2">
                <img alt="" src={this.props.user.avatar ? this.props.user.avatar : round_logo} style={{width:"75px", height: "75px"}}/>
            </div>
            <div className="pt-3 pb-4">
                <p className="txt-black-big mb-0">{ci.type=== "BUSINESS" ? ci.personalData.companyName : (ci.personalData.forename + " " + ci.personalData.surname)}</p>
                {this.getCustomerContact("PHONE", "txt-black-big")}
                <p className="txt-grey-small mb-0">{T('Client number')}: {ci.number}</p>
                {this.getCustomerContact("EMAIL", "txt-grey-small")}
                <NavLink exact to="/customer/data">
                <span className=" ebok-color txt-violet-xsmall">
                    {T('Edit your data')}<br/>
                </span>
                </NavLink>
                <NavLink exact to="/customer/history">
                <span className=" ebok-color txt-violet-xsmall">
                    {T('Activity history')}<br/>
                </span>
                </NavLink>
            </div>
            <div className="top-border pb-4">
                <p className="txt-black-medium mb-0 mt-4">{T('Default invoice type')}:</p>
                <p className="txt-black-small mb-0">{einvoice === true ? "E-faktura" : "Standardowa faktura"}</p>
                <NavLink exact to="/customer/data">
                    <span className="ebok-color txt-violet-xsmall mt-4">{T('Change')}<br/></span>
                </NavLink>
            </div>
            <div className="top-border">
                <p className="txt-black-big mb-0 mt-4"><strong>{T('CALL CENTER')}</strong></p>
                <p className="txt-black-big"><strong>{oi.phoneNumber}</strong></p>
            </div>
            <div className="pt-3 top-border text-center">
                <TokenButton/>
            </div>
        </div>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(CustomerInfo);
