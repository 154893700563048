import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {FormGroup, Input} from "reactstrap";


class DtFilterSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.getValue()
        };
    }

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    changeValue(e) {
        let newParams = this.dataTableParams();
        newParams.filters[this.props.filter.field + this.props.filter.operation] = {
            value: e.target.value,
            field: this.props.filter.field,
            operation: this.props.filter.operation,
            type: 'TEXT'
        };
        this.props.setDataTableParams(newParams, this.props.id);
        this.setState({value: e.target.value})
    }

    getValue() {
        if (this.dataTableParams().filters[this.props.filter.field + this.props.filter.operation]) {
            return this.dataTableParams().filters[this.props.filter.field + this.props.filter.operation].value
        } else {
            return '';
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.getValue() !== this.state.value) {
            this.setState({value: ''})
        }
    }

    render() {
        return <div style={{marginLeft: '10px'}}><p
            style={{marginBottom: '0px', fontSize: '0.875em'}}>{this.props.filter.label}</p>
            <FormGroup>
                 <Input className="w-100 h-25 txt-input form-control login-box mt-1"
                       value={this.state.value} type="select" onChange={this.changeValue.bind(this)}>
                    <option key="" value="">Select</option>
                    {this.props.filter.selectOptions.map((opt, i) => {
                        return <option key={`opt-${i}`} value={opt.value}>{opt.label}</option>
                    })}
                </Input>

            </FormGroup>
        </div>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DtFilterSelect);