import React, { Component } from "react";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { CustApi } from "app/api/CustApi";
import CustomerData from "app/app-components/myData/CustomerData";
import CustomerContact from "app/app-components/myData/CustomerContact";
import CustomerConsents from "app/app-components/myData/CustomerConsents";
import CustomerInvoiceType from "app/app-components/myData/CustomerInvoiceType";

class ViewCustomerData extends Component {

    componentDidMount() {
        CustApi.customerInfo().then(r => {
            this.props.setCustomerData(r.data);
            this.props.setCustomerInfo(r.data);
        });

        if (!this.props.customerData.customerConsents) {
            CustApi.customerConsents().then(r => {
                this.props.setCustomerConsents(r.data);
            });
        }

    }

    render() {
        return this.props.dashboard.customerInfo ? <div className="container bgr-grey">
                <div className="row pt-2 px-1 px-md-0">
                    <CustomerData/>
                    <div className="col px-0 px-md-3 py-3">
                        <CustomerContact/>
                        <CustomerInvoiceType/>
                        <CustomerConsents/>
                    </div>
                </div>
            </div>
            :
            ""
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewCustomerData);
