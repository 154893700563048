import React, {Component} from 'react';
import AppWrapper from "app/app-main/AppWrapper";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {BrowserRouter as Router} from "react-router-dom";

import {SecApi} from "app/api/SecApi";
import "assets/css/loader.css"
import "assets/css/texts.css"
import "assets/css/backgrounds.css"
import "assets/css/buttons.css"
import "assets/css/ebok-color.css"
import {OperApi} from "app/api/OperApi";
import {DebugSidebar} from "app/app-components/common/DebugSidebar";
import Toasts from "app/app-components/common/Toasts";
import AppContentAnonymous from "./AppContentAnonymous";
import Logger from "app/Logger";
import ViewAppUnavailable from "../app-views/common/ViewAppUnavailable";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            themeLoaded: false,
            userLoaded: false,
        };

        this.props.setLang(localStorage.getItem("lang") ? localStorage.getItem("lang") : "pl")

    }

    componentDidMount() {

        OperApi.theme().then((r) => {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.id = 'theme-style';
            style.innerHTML = r.data.css;
            document.getElementsByTagName('head')[0].appendChild(style);
            this.props.setTheme(r.data);
            this.setState({themeLoaded: true});
        });

        OperApi.getOperatorConfig().then((r) => {
            this.props.setOperatorConfig(r.data)
        });

        OperApi.getOperatorContact().then(r => {
            this.props.setOperatorInfo(r.data)
        });

        SecApi.user().then(user => {
            if (user.data) {
                this.props.setUser(user.data);
                Logger.debug('App - user already logged in', user.data);
            }
            this.setState({userLoaded: true});
        });
    }

    render() {

        if (this.appNotReady()) return this.getLoader(true);

        let content = this.props.user.username ? <AppWrapper/> : <AppContentAnonymous/>;

        return (
            <div>
                {this.getLoader(this.props.loading)}
                <Router>
                    {this.props.appUnavailable? <ViewAppUnavailable/> : content}
                </Router>
                <DebugSidebar/>
                <Toasts/>
            </div>
        )
    }

    appNotReady() {
        return !this.state.themeLoaded || !this.state.userLoaded;
    }

    getLoader(isLoading) {
        return <div className="loader" style={{display: isLoading ? 'block' : 'none'}}>
            <div className="loader-top"/>
            <div className="spinner-border  text-primary spinner-lg" role="status"/>
        </div>;
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(App);


