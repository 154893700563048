import React from "react";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { SecApi } from "app/api/SecApi";
import LangPicker from "app/i18n/LangPicker";
import { NavLink, withRouter } from "react-router-dom";
import T from "app/i18n/T";
import { Navbar } from "reactstrap";
import { CustApi } from "app/api/CustApi";
import home from "assets/img/home.svg";
import "assets/css/all.min.css";
import Animista, { AnimistaTypes } from "react-animista";

class AppTopbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpened: false,
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  toggle = () => {
    if (window.innerWidth < 991) {
      this.setState((prevState) => ({
        navbarOpen: !prevState.navbarOpen
      }));
    }
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  logout = (that) => {
    return () => {
      that.props.clearUser();
      SecApi.logout();
      that.props.history.push("/");
    };
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    CustApi.getMessagesCount().then((json) => {
      this.props.setMsgCounter(json.data);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  toggleMobileMenu = () => {
    this.setState((oldState) => ({
      mobileMenuOpened: !oldState.mobileMenuOpened
    }));
  };

  render() {
    const navbarContent = <>
      <ul className="navbar-nav ml-auto d-flex mr-auto">
        <NavLink exact to="/" onClick={this.toggleMobileMenu} className="ebok-no-underline">
          <li className="justify-content-start d-flex align-items-center pt-0 pt-lg-2">
                        <span
                          className="nav-border-color menu-1-txt justify-content-center d-flex align-items-center ml-lg-4 ml-xl-5 px-3">
                            <img alt="" src={home} />
                        </span>
          </li>
        </NavLink>

        <NavLink exact to="/payments" onClick={this.toggleMobileMenu} className="ebok-no-underline">
          <li className="justify-content-start d-flex align-items-center pt-0 pt-lg-2 pl-0 pl-lg-2">
                        <span
                          className="nav-border-color menu-1-txt justify-content-center d-flex align-items-center px-3">
                            {T("INVOICE AND PAYMENTS")}
                        </span>
          </li>
        </NavLink>

        {this.props.dashboard.customerInfo && this.props.dashboard.customerInfo.generatesCdrs ?
          <NavLink exact to="/billing" onClick={this.toggleMobileMenu} className="ebok-no-underline">
            <li className="justify-content-start d-flex align-items-center pt-0 pt-lg-2 pl-0 pl-lg-2">
                        <span
                          className="nav-border-color menu-1-txt justify-content-center d-flex align-items-center px-3">
                            {T("BILLING")}
                        </span>
            </li>
          </NavLink>
          : ""}

        <NavLink exact to="/services" onClick={this.toggleMobileMenu} className="ebok-no-underline">
          <li className="justify-content-start d-flex align-items-center pt-0 pt-lg-2 pl-0 pl-lg-2">
                        <span
                          className="nav-border-color menu-1-txt justify-content-center d-flex align-items-center px-3">
                            {T("SERVICES")}
                        </span>
          </li>
        </NavLink>

        <NavLink exact to="/mydata" onClick={this.toggleMobileMenu} className="ebok-no-underline">
          <li className="justify-content-start d-flex align-items-center pt-0 pt-lg-2 pl-0 pl-lg-2">
                        <span
                          className="nav-border-color menu-1-txt justify-content-center d-flex align-items-center px-3">
                            {T("MY DATA")}
                        </span>
          </li>
        </NavLink>
        <NavLink exact to="/messages" onClick={this.toggleMobileMenu} className="ebok-no-underline">
          <li className="justify-content-start d-flex align-items-center pt-0 pt-lg-2 pl-0 pl-lg-2 message-btn">
                        <span
                          className="nav-border-color menu-1-txt justify-content-center d-flex align-items-center px-3">
                            {T("MESSAGES")}
                          {this.props.msgCounter !== 0 &&
                            <div className="message background-main">
                              <p className="p-0">
                                {this.props.msgCounter}
                              </p>
                            </div>
                          }
                        </span>
          </li>
        </NavLink>
        <span className="pt-0 pt-md-1 pl-3 pl-lg-0">
                <LangPicker />
                </span>
      </ul>
      <div>
        <button className="btn btn-primary btn-block btn-lg btn-signin-thin background-main"
                onClick={this.logout(this)}>
          <i className="far fa-sign-out-alt" />
        </button>
      </div>
    </>;
    let navbarMobile = <>
      <div className="align-items-center-2 pt-2">
        <NavLink exact to="/">
          <img alt="" className="img-fluid logo order-0 mx-auto d-block" src="/logo.png" />
        </NavLink>
      </div>
      <Animista type={this.state.mobileMenuOpened ? AnimistaTypes.SLIDE_LEFT : AnimistaTypes.SLIDE_RIGHT}
                tag="div"
                className="mobile-bgr float-right shadow-sm"
                style={{
                  marginRight: this.state.mobileMenuOpened ? "-100px" : "-150px",
                  zIndex: "101",
                  height: "100%",
                  overflowY: "scroll"
                }}
                duration="10000">
        <div className="mb-4">
                        <span onClick={this.toggleMobileMenu}>
                            <i className="far fa-2x fa-times ebok-color" />
                        </span>
        </div>
        {navbarContent}
      </Animista>
      <div>
        <div style={{ position: "fixed", right: 20, top: 20, zIndex: "100" }}>
          <span onClick={this.toggleMobileMenu}><i className="fas fa-2x fa-bars ebok-color" /></span>
        </div>
      </div>
    </>;
    let navbar = <div className="bgr-w">
      <div className="container nav-container">
        <Navbar expand="lg" light>
          <NavLink exact to="/">
                            <span className="logo">
                                <img alt="" className="img-fluid order-0" src="/logo.png" />
                            </span>
          </NavLink>
          {navbarContent}
        </Navbar>
      </div>
    </div>;

    return this.state.width > 991 ? navbar : navbarMobile;
  }
}


export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(AppTopbar));
