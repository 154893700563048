import React, { Component } from "react";
import { mapStorageDispatchToProps, mapStorageToProps } from "../../redux/mappers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ViewAppUnavailable extends Component {

  reload = () => {
    this.props.setAvailable();
    window.location.reload();
  };

  goToHomepage = () => {
    this.props.setAvailable();
    this.props.clearUser();
    this.props.history.push("/");
  };


  render() {
    return <>
      <div style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "200px",
        width: "100%",
        textAlign: "center"
      }}>
        <img alt="" className="img-fluid order-0" src="/logo.png" />
        <br />
        System chwilowo niedostępny. Prosimy spróbować później.
        <br />
        <div className="btn btn-primary mt-4" onClick={() => this.reload()}>Spróbuj ponownie</div>
        <div className="ebok-color pointer mt-2" onClick={() => this.goToHomepage()}>Powrót na stronę główną</div>
      </div>
    </>;
  }
}

export default withRouter(connect(mapStorageToProps, mapStorageDispatchToProps)(ViewAppUnavailable));
