import { Api } from "./Api";

export class CustApi extends Api {


    static apiUrl = (window.location.port === '3000' && window.var.CUSTAPI_URL) || '/cust-api';

    static theme() {
        return CustApi.get('/theme')
    }

    static customerInfo() {
        return CustApi.get('/customer/info')
    }

    static customerConsents() {
        return CustApi.get('/customer/consents')
    }

    static sendConsent(consent) {
        return CustApi.post('/customer/consents/change', consent)
    }

    static sendCustomerInfo(customerInfo) {
        return CustApi.post('/customer/info/change/contact-data', customerInfo)
    }

    static changeInvoiceType(consent) {
        return CustApi.post('/customer/info/change/eInvoice', consent)
    }

    static sendReport(reportData) {
        return CustApi.post('/customer/report/send', reportData)
    }

    static customerLastSettlement() {
        return CustApi.getCanError('/customer/last-settlement')
    }

    static customerServices() {
        return CustApi.get('/customer/services')
    }

    static customerSettlements() {
        return CustApi.get('/payment/settlements')
    }

    static customerPayments(range) {
        return CustApi.get('/payment/payments/' + range)
    }

    static orderInvoice(invoiceUid) {
        return CustApi.get('/payment/order/invoice/' + invoiceUid)
    }

    static downloadInvoice(invoiceUid) {
        return CustApi.get('/payment/download/invoice/' + invoiceUid)
    }

    static orderInvoiceBilling(invoiceUid) {
        return CustApi.get('/payment/order/invoice-billing/' + invoiceUid)
    }

    static downloadInvoiceBilling(invoiceUid) {
        return CustApi.get('/payment/download/invoice-billing/' + invoiceUid)
    }


    static getAllMessages() {
        return CustApi.get('/messages/all')
    }

    static getDashboardMessages() {
        return CustApi.get('/messages/dashboard')
    }

    static getMessagesCount() {
        return CustApi.get('/messages/count')
    }

    static markMessageRead(msgId) {
        return CustApi.get('/messages/markRead/' + msgId)
    }

    static markMessageDelete(msgId) {
        return CustApi.delete('/messages/delete/' + msgId)
    }

    static getCallDataRecords(criteria) {
        if (criteria) {
            return CustApi.post('/call-data/records', criteria)
        }
    }

    static getHistory(criteria) {
        return CustApi.post('/history', criteria)
    }

}
