import React from "react";
import {Carousel, CarouselIndicators, CarouselItem} from 'reactstrap';
import "assets/css/carousel.css"
import T from "app/i18n/T";
import {CustApi} from "app/api/CustApi";
import Util from "app/Utils";
import _ from "lodash"

export default class CustomerMsg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            messages: []
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        CustApi.getDashboardMessages().then((json) => {
            this.setState({
                messages: json.data
            })
        })
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.messages.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.messages.length - 1 : this.state.activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({activeIndex: newIndex});
    }

    render() {
        const slides = this.state.messages.map((item, i) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting()}
                    onExited={this.onExited()}
                    key={i}
                >
                    <span className="row">
                        <span className="col-8">
                            <p className="txt-black-medium">{item.title}</p>
                        </span>
                        <span className="col-4 text-right">
                            <p className="txt-grey-small mb-0">{Util.getFormattedDate(item.createdDt)}</p>
                        </span>
                    </span>


                    <p className="txt-black-small mb-0 txt-news-short-4">{item.content}</p>
                </CarouselItem>
            );
        });

        return (
            <div className="bgr-w h-100 px-4 pb-xl-0">
                <div className="pt-3 pb-4 pb-lg-2 pb-xl-2" style={{minHeight: 200}}>
                    <p className="ebok-color txt-violet-big mb-3">
                        {T('News')}
                    </p>
                    {!_.isEmpty(this.state.messages) ?
                        <Carousel activeIndex={this.state.activeIndex}
                                  next={this.next}
                                  previous={this.previous}
                        >
                            {slides}
                        </Carousel>
                        :
                        <p className="txt-black-small mb-0 txt-news-short-4">{T('No messages to display')}</p>
                    }
                    <CarouselIndicators items={this.state.messages}
                                        activeIndex={this.state.activeIndex}
                                        onClickHandler={this.goToIndex}
                                        className="circle"/>
                </div>
            </div>
        );
    };
}
