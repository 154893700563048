import React, {Component} from 'react';
import 'assets/css/table.css'
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledButtonDropdown
} from "reactstrap";

class DtPagination extends Component {

    dataTable = () => {
        return this.props.dataTable[this.props.id]
    };

    dataTableParams = () => {
        return this.props.dataTable[this.props.id + "_params"]
    };

    pages(currentPage, totalPages) {
        const start = currentPage <= 4 ? 0 : currentPage - 4;
        return Array.from({length: totalPages}, (_, key) => key + 1)
            .slice(start, currentPage + 3)
            .map((p, i) => <PaginationItem key={i} active={currentPage === p}>
                    <PaginationLink className={currentPage === p ? 'background-main' : 'ebok-color'} onClick={() => {
                        this.clickPage(p);
                    }
                    }>{p}</PaginationLink>
                </PaginationItem>
            );
    }

    clickPage(page) {
        this.setPage(page);
        this.props.getData();
    }

    setPage(page) {
        let newParams = this.dataTableParams();
        newParams.page = page;
        this.props.setDataTableParams(newParams, this.props.id);
        this.props.getData();
    }

    setPageSize(size) {
        let newParams = this.dataTableParams();
        newParams.pageSize = size;
        this.props.setDataTableParams(newParams, this.props.id);
        this.props.getData();
    }

    pageSizer() {
        return <> {this.props.labels.pageSize}:
            <UncontrolledButtonDropdown direction="up" size="sm" style={{verticalAlign: 'initial'}}>
                <DropdownToggle caret color="">
                    {this.dataTableParams().pageSize}
                </DropdownToggle>
                <DropdownMenu>
                    {
                        [10, 20, 50, 100].map((size, i) => {
                            return <DropdownItem key={i}
                                                 onClick={() => this.setPageSize(size)}>{size}</DropdownItem>
                        })
                    }
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </>
    }

    render() {
        const currentPage = this.dataTableParams().page;
        const totalPages = Math.ceil(this.dataTable().total / this.dataTableParams().pageSize);

        return <div className="row">

            <div className="col d-flex">
                <div style={{fontSize: '0.875em', paddingTop: '1px', marginLeft: '10px'}}>
                    {this.pageSizer()}
                </div>
            </div>

            <div className="col d-flex justify-content-end">

                <div style={{marginLeft: '10px', marginRight: '10px', fontSize: '0.875em', paddingTop: '6px'}}>
                    {this.props.labels.page} {currentPage} {this.props.labels.of} {totalPages}
                </div>

                <div style={{marginRight: '10px'}}>
                    <Pagination size="sm" aria-label="Page navigation">
                        <PaginationItem disabled={currentPage < 2}>
                            <PaginationLink className="ebok-color" first onClick={() => this.setPage(1)}/>
                        </PaginationItem>
                        <PaginationItem disabled={currentPage < 2}>
                            <PaginationLink className="ebok-color" previous onClick={() => this.setPage(currentPage - 1)}/>
                        </PaginationItem>

                        {this.pages(currentPage, totalPages)}

                        <PaginationItem disabled={currentPage >= totalPages}>
                            <PaginationLink className="ebok-color" next onClick={() => this.setPage(currentPage + 1)}/>
                        </PaginationItem>
                        <PaginationItem disabled={currentPage >= totalPages}>
                            <PaginationLink className="ebok-color" last onClick={() => this.setPage(totalPages)}/>
                        </PaginationItem>
                    </Pagination>
                </div>
            </div>
        </div>

    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(DtPagination);