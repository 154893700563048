import React, {Component} from 'react';
import Article from "app/app-components/common/Article";

export class ViewCookies extends Component {

    render() {
        return <div className="container">
            <Article articleName="cookies"/>
        </div>
    }
}
