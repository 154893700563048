import moment from "moment";
import Moment from "moment";
import React from "react";
import { store } from "app/redux/redux";
import T from "./i18n/T";


const Utils = {
  formatTime: param => param != null ? moment(param).format("DD.MM.YYYY HH:mm:ss") : "-",
  formatDate: param => {
    if (param != null) {
      const momentObj = moment(param);
      const year = momentObj.year();

      if (year > 2300) {
        return T("Indefinitely");
      } else {
        return momentObj.format("DD.MM.YYYY");
      }
    } else {
      return "-";
    }
  },
  checkIfDateIsGreaterThanToday: date => {
    return moment(date).isAfter(moment(), 'day')
  },
  formatBooleanToYesNo: param => param ? <i className="fa fa-check" /> : "",
  tryFormatJsonOrEmpty: function(param) {
    if (param) {
      try {
        return JSON.parse(param);
      } catch (e) {
        return JSON.parse("{}");
      }
    }
  },
  updateDeepObject: function(form, fields, value) {
    const key = fields.shift();
    if (fields.length > 0) {
      this.updateDeepObject(form[key], fields, value);
    } else {
      form[key] = value;
    }
  },
  formatBadge: param => <span className="tag-wrapper badge badge-secondary">{param}</span>,
  formatDataExtract: function(data, columns) {
    return columns.split("|").map((c, i) => {
      return <div key={i}>{c}: {data[c]}</div>;
    });
  },
  hasPrivilege: function(action) {
    return store.getState().user.privileges.includes(action);
  },

  downloadByBlob: function(filename, data, type) {
    let file = null;
    if (type != null && (type === "application/octet-stream" || type === "application/pdf")) { //base64
      let decoded = atob(data);
      let bytes = new Array(decoded.length);
      for (let i = 0; i < decoded.length; i++)
        bytes[i] = decoded.charCodeAt(i);
      file = new Blob([new Uint8Array(bytes)], { type: type });
    } else
      file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      let a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  },

  addPrecision(value) {
    if (!value) {
      return "0.00";
    }
    return value.toFixed(2);
  },

  getFormattedDate(date, format) {
    if (format) {
      return moment(date).format(format);
    } else {
      return moment(date).format("YYYY-MM-DD HH:mm");
    }
  },

  sortByIssueDate(data) {
    return data.rows.sort((a, b) => new Moment(b.issueDate).format("YYYYMMDD") - new Moment(a.issueDate).format("YYYYMMDD"));
  },

  sortContracts(rows) {
    const sortedRows = [...rows];
    sortedRows.sort((a, b) => {
      const dateA = new Moment(a.contract.minimalProvisionUntil).format("YYYYMMDD");
      const dateB = new Moment(b.contract.minimalProvisionUntil).format("YYYYMMDD");
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;

      return a.contract.price - b.contract.price;
    });
    return sortedRows;
  }

};

export default Utils;
