import React, { Component } from "react";
import "assets/css/dots.css";
import "assets/css/ebok-color.css";
import { connect } from "react-redux";
import { mapStorageDispatchToProps, mapStorageToProps } from "app/redux/mappers";
import { CustApi } from "app/api/CustApi";
import LastInvoice from "app/app-components/payments/LastInvoice";
import InvoicesTable from "app/app-components/payments/InvoicesTable";
import PaymentsChart from "app/app-components/payments/PaymentsChart";

class ViewPayments extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lastInvoicePresent: false
    };
  }

  componentDidMount() {
    if (!this.props.dashboard.customerInfo) {
      CustApi.customerInfo().then(r => {
        this.props.setCustomerInfo(r.data);
        this.props.setCustomerData(r.data);
      });
    }
    CustApi.customerLastSettlement().then(r => {
      this.setState({
        lastInvoice: r.data,
        lastInvoicePresent: true
      });
    });

  }


  render() {
    return <div className="container bgr-grey py-4">
      <div className="row">
        <div className="col col-12 col-lg-6 col-xl-4 px-3">
          <div className="bgr-w h-100 px-4 pb-4 m-0 p-0">
            <LastInvoice invoice={this.state.lastInvoice ? this.state.lastInvoice.invoice : null}
                         customerFinancialStatus={this.state.lastInvoice ? this.state.lastInvoice.customerFinancialStatus : null} />
          </div>
        </div>
        <div className="col col-12 col-lg-6 col-xl-8 px-3 pt-3 pt-lg-0 pt-xl-0 mt-xl-0"
             style={{ minHeight: "500px" }}>
          <div className="bgr-w h-100 px-4 pb-4 m-0 p-0">
            <div className="pt-3 pb-3">
              <PaymentsChart />
            </div>
          </div>
        </div>
      </div>
      <InvoicesTable data={this.state.invoices} />
    </div>;
  }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewPayments);
