import React, {Component} from "react";
import T from "app/i18n/T";
import {StatusDot} from "app/app-components/common/StatusDot";
import Utils from "app/Utils";
import {Button, PopoverBody, PopoverHeader, UncontrolledPopover} from "reactstrap";
import QRCode from "qrcode.react"
import {CopyToClipboard} from "react-copy-to-clipboard";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "../../redux/mappers";


class LastInvoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false
        }
    }

    formatAccNo() {
        return this.props.invoice.bankAccountNumber.slice(0, 2) + " " + this.props.invoice.bankAccountNumber.slice(2, 26).match(/.{1,4}/g).join(" ")
    }

    qrText() {
        return [
            this.props.dashboard.operatorInfo.recipientNip,
            "PL",
            this.props.invoice.bankAccountNumber,
            (this.props.invoice.remainingDebit * 100).toFixed(0),
            this.props.dashboard.operatorInfo.recipientName,
            this.props.invoice.number,
            "", "", ""
        ].join("|")
    }

    render() {
        if (this.props.invoice) {
            return <>
                    <div className="pt-3 pb-3">
                        <p className="ebok-color txt-violet-big mb-0">{T('Last Invoice')}</p>
                    </div>
                    <div className="row pt-0">
                        <div className="col col-12">
                            <div className="row dane-mb">
                                <div className="col col-6">
                                    <p className="txt-black-medium mb-0"><strong>{T('Invoice number')}:</strong><br/>
                                    </p>
                                </div>
                                <div className="col d-flex justify-content-end justify-content-lg-start">
                                    <p className="txt-black-small mb-0">{this.props.invoice.number}</p>
                                </div>
                            </div>
                            <div className="row dane-mb">
                                <div className="col col-6">
                                    <p className="txt-black-medium mb-0"><strong>{T('Issued date')}:</strong><br/></p>
                                </div>
                                <div className="col d-flex justify-content-end justify-content-lg-start">
                                    <p className="txt-black-small mb-0">{Utils.formatDate(this.props.invoice.issueDate)}</p>
                                </div>
                            </div>
                            <div className="row dane-mb">
                                <div className="col col-6">
                                    <p className="txt-black-medium mb-0"><strong>{T('Due date')}:</strong><br/></p>
                                </div>
                                <div className="col d-flex justify-content-end justify-content-lg-start">
                                    <p className="txt-black-small mb-0">{Utils.formatDate(this.props.invoice.paymentDueDate)}</p>
                                </div>
                            </div>
                            <div className="row dane-mb">
                                <div className="col col-6">
                                    <p className="txt-black-medium mb-0"><strong>{T('Payment status')}:</strong><br/>
                                    </p>
                                </div>
                                <div
                                    className="col d-flex align-items-center justify-content-end justify-content-lg-start col-6">
                                    <div className="d-inline-flex align-items-center">
                                        <StatusDot status={this.props.invoice.isPaid}/>
                                        <span
                                            className="txt-black-small mb-0 ml-2">{this.props.invoice.isPaid ? T('Paid') : T('Not paid')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row dane-mb d-flex align-items-center pt-2">
                                <div className="col col-6">
                                    <p className="txt-black-medium"><strong>{T('Due Last Invoice')}:</strong></p>
                                </div>
                                <div className="col col-6">
                                    <p className="txt-black-vbig d-flex justify-content-end justify-content-lg-start">
                                        <strong>{Utils.addPrecision(this.props.invoice.remainingDebit)} zł</strong><br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="row pt-0">
                        <div className="col col-12">
                            <div className="row dane-mb">
                                <div className="col col-12">
                                    <p className="txt-black-medium mb-0"><strong>{T('Account No.')}:</strong><br/></p>
                                </div>
                            </div>
                            <div className="row dane-mb">
                                <div className="col">
                                    <p className="txt-black-small mb-0"
                                       style={{fontSize: 15}}>{this.formatAccNo()} &nbsp;

                                        <CopyToClipboard text={this.formatAccNo()}
                                                         onCopy={() => this.setState({copied: true})}>
                                            <Button color="link" style={{marginLeft: 0, paddingLeft: 0}}>
                                                <i className={"far fa-copy " + (this.state.copied ? "text-warning" : "ebok-color")}
                                                   title="Skopiuj numer konta"/>
                                            </Button>
                                        </CopyToClipboard>

                                        {window.innerWidth >= 991 &&
                                        <Button id="acc-details" color="link" style={{marginLeft: 0, paddingLeft: 0}}>
                                            <i className="far fa-info-square ebok-color"
                                               title={T('Show transfer details')}/>
                                        </Button>
                                        }

                                    </p>

                                    {window.innerWidth >= 991 &&
                                    <UncontrolledPopover placement="auto" target="acc-details">
                                        <PopoverHeader>{T('Dane do przelewu')}</PopoverHeader>
                                        <PopoverBody style={{width: 350}}>

                                            <p className="nowrap">
                                                <strong>{T('Nr rachunku')}: {this.formatAccNo()}</strong>
                                            </p>

                                            <p>
                                                <strong>{T('Nazwa odbiorcy')}:</strong> {this.props.dashboard.operatorInfo.recipientName}
                                            </p>

                                            <p>
                                                <strong>{T('Adres odbiorcy')}:</strong> {this.props.dashboard.operatorInfo.recipientAddress}
                                            </p>

                                            <p>
                                                <strong>{T('Tytuł płatności')}:</strong> {this.props.invoice.number}
                                            </p>

                                            <p>
                                                <strong>{T('Kwota')}:</strong> {Utils.addPrecision(this.props.invoice.remainingDebit)} zł
                                            </p>

                                            <p/>

                                            <p>
                                                <strong>{T('Zrób przelew za pomocą kodu QR')}:</strong>
                                            </p>

                                            <div style={{textAlign: 'center'}}>
                                                <QRCode value={this.qrText()} size={200}/>
                                            </div>

                                        </PopoverBody>
                                    </UncontrolledPopover>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <header/>
                    <hr/>
                    <div className="row d-flex align-items-center">
                        <div className="col col-12 d-flex justify-content-end">
                            <div className="d-inline-flex align-items-center">
                                <p className="txt-black-medium"><strong>{T('Account balance')}:</strong><br/></p>
                                <p className="txt-black-vbig pl-2">
                                    <strong>{Utils.addPrecision(this.props.customerFinancialStatus.balance)} zł</strong><br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </>;
        } else {
            return <>
                <div className="pt-3 pb-3">
                    <p className="ebok-color txt-violet-big mb-0">{T('Last Invoice')}</p>
                </div>
                <div className="row pt-0">
                    <div className="col col-12 txt-black-small">
                        Brak wystawionych faktur
                    </div>
                </div>
            </>
        }
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(LastInvoice);