import React, {Component} from "react";
import T from "app/i18n/T";
import {connect} from "react-redux";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {Button, Form, FormFeedback, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Logger from "app/Logger";
import {CustApi} from "app/api/CustApi";
import {getMsg, isValid} from "app/app-components/common/Validation";

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contactForm: false,
            title: "",
            content: ""
        };

        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState({
            contactForm: !this.state.contactForm
        })
    }

    editField = (key, value) => {
        this.setState({
            [key]: value
        })
    };

    sendContactRequest = () => {
        Logger.debug("Wysyłam req");
        Logger.debug(this.state);
        CustApi.sendReport({content: this.state.content, title: this.state.title}).then(this.validateResponse)
    };

    validateResponse = (r) => {
        if (r.meta.status === "SUCCESS") {
            this.setState({contactForm: false});
            this.props.pushToast('s', T('Report was sent successfully'))
        } else {
            this.props.pushToast('e', T('Error occured while sending report'))
        }

        this.props.setValidation(r.meta.validation);
    };

    contactModal() {
        return <div>

            <Modal isOpen={this.state.contactForm} toggle={this.toggle} style={{top: 60}}>
                <ModalHeader toggle={this.toggle}>{T('Contact form')}</ModalHeader>
                <Form onSubmit={(e) => console.log(e)}>
                    <ModalBody>
                        <InputGroup className="mb-3">
                            <Input type="text" placeholder={T('Report title')}
                                   onChange={(e) => this.editField("title", e.target.value)}
                                   invalid={isValid("title")}/>
                            <FormFeedback>{getMsg("title")}</FormFeedback>
                        </InputGroup>
                        <InputGroup>
                            <Input type="textarea" rows={10} placeholder={T('Report content')}
                                   onChange={(e) => this.editField("content", e.target.value)}
                                   invalid={isValid("content")}/>
                            <FormFeedback>{getMsg("content")}</FormFeedback>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.sendContactRequest}>{T('Send report')}</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>{T('Cancel')}</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    }

    render() {

        if (!this.props.dashboard.operatorInfo) {
            return null
        }
        return <div className="bgr-w h-100 px-4 pb-xl-0">

            <div className="pt-3 pb-2">
                <p className="ebok-color txt-violet-big mb-3">
                    {T('Report a problem')}
                </p>
            </div>

            <div className="dane-mb">
                <span onClick={this.toggle} style={{cursor: 'pointer'}}>
                    <div className="d-inline-flex dane-mb-midi">
                        <div>
                            <i className="fas fa-lg fa-comment-alt ebok-color"/>
                        </div>
                        <div>
                            <span className="txt-black-small ml-3">
                                {T('Contact form')}<br/>
                            </span>
                        </div>
                    </div>
                </span>
            </div>

            {/*<div className="dane-mb">*/}
            {/*    <a href={this.props.dashboard.operatorInfo.chatPath}>*/}
            {/*        <div className="d-inline-flex dane-mb-midi">*/}
            {/*            <div>*/}
            {/*                <i className="fas fa-lg fa-comment-alt ebok-color"/>*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <span className="ebok-color txt-violet-small ml-3">*/}
            {/*                    {T('chat with specialist')}<br/>*/}
            {/*                </span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </a>*/}
            {/*</div>*/}

            <div className="d-inline-flex dane-mb">
                <div>
                    <i className="fas fa-lg fa-phone-alt ebok-color"/>
                </div>
                <div>
                    <p className="txt-black-small ml-3">
                        {T('Phone')}: {this.props.dashboard.operatorInfo.phoneNumber}
                    </p>
                </div>
            </div>

            <a href={"mailto:" + this.props.dashboard.operatorInfo.contactMail}>
                <div className="dane-mb">
                    <div className="d-inline-flex pb-2 pb-xl-0">
                        <div>
                            <i className="fas fa-lg fa-envelope-open-text ebok-color"/>
                        </div>
                        <div className="mt-1">
                            <p className="txt-black-small ml-3">e-mail: <span>
                                {this.props.dashboard.operatorInfo.contactMail}
                            </span>
                            </p>
                        </div>
                    </div>
                </div>
            </a>

            {this.contactModal()}

        </div>;
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(Contact);