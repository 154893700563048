import React, {Component} from 'react';
import {CustApi} from "app/api/CustApi";
import DataTable from "app/app-components/common/DataTable/DataTable";
import Utils from "app/Utils";
import {dataTableLang} from "app/i18n/DataTableLang";
import T from "app/i18n/T";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {connect} from "react-redux";

class ViewBilling extends Component {

    render() {

        if (!this.props.dashboard.customerInfo || !this.props.dashboard.customerInfo.generatesCdrs) {
            return '';
        }

        return <>
            <div className="container bgr-grey pt-3 pb-3">
                <div className="bgr-w">
                    <DataTable
                        id="ViewBillingTable"
                        api={CustApi.getCallDataRecords}
                        cols={[
                            {header: T("Calling number"), field: "callingNumber", mobileHeader: true},
                            {header: T("Dialed number"), field: "dialedNumber"},
                            {header: T("Start"), field: "callBegin", formatter: (v) => Utils.getFormattedDate(v)},
                            {header: T("Type"), field: "serviceType"},
                            {header: T("Duration"), field: "durationFormatted", mobileHeader: true},
                            {header: T("Transfer"), field: "transferFormatted"},
                            {
                                header: T("Gross price"),
                                field: "grossPrice",
                                formatter: (v) => <>{Utils.addPrecision(v)} zł</>,
                                mobileHeader: true
                            },
                            {header: T("Direction"), field: "direction"}
                        ]}
                        labels={dataTableLang()}
                        pageable={false}
                    >
                    </DataTable>
                </div>
            </div>
        </>
    }
}

export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewBilling)
