import React, {Component} from "react";
import {SecApi} from "app/api/SecApi";
import T from "app/i18n/T";
import {mapStorageDispatchToProps, mapStorageToProps} from "app/redux/mappers";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {FormFeedback, FormGroup, Input} from "reactstrap";
import {getMsg, isValid} from "app/app-components/common/Validation";


class ViewConsultantRegistration extends Component {

    register = (event) => {
        event.preventDefault();

        const data = {
            userName: event.target.userName.value,
            password: event.target.password.value,
            repeatPassword: event.target.repeatPassword.value,
            token: event.target.token.value,

        };

        SecApi.registerConsultant(data).then(
            (r) => {
                if (r.meta.status === 'SUCCESS') {
                    this.props.pushToast('s', T('Registration success!'));
                    this.props.history.push('/consultant/login')
                }
                this.props.setValidation(r.meta.validation);
            },
            (error) => this.props.pushToast('e', error)
        );
    };

    render() {
        return <>

            <div className="row d-flex justify-content-center">
                <div className="col col-12 col-sm-8 col-lg-6 col-xl-4">

                    <p className="ebok-color profile-name-card">
                        {T('Consultant registration')}
                    </p>

                    <form className="form-signin mt-4" onSubmit={this.register}>

                        <FormGroup>
                            <Input
                                className="form-control login-box"
                                id="userName" name="userName" required=""
                                placeholder={T('Consultant username')} autoFocus=""
                                invalid={isValid("userName")}>
                            </Input>
                            <FormFeedback>{getMsg("userName")}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Input
                                className="form-control login-box"
                                type="text" id="token" name="token"
                                required="" placeholder={T('Token')} invalid={isValid("token")}/>
                            <FormFeedback>{getMsg("token")}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Input
                                className="form-control login-box"
                                type="password" id="password" name="password"
                                required="" placeholder={T('Password')} invalid={isValid("password")}/>
                            <FormFeedback>{getMsg("password")}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                className="form-control login-box"
                                type="password" id="repeatPassword" name="repeatPassword"
                                required="" placeholder={T('Repeat password')} invalid={isValid("repeatPassword")}/>
                            <FormFeedback>{getMsg("repeatPassword")}</FormFeedback>
                        </FormGroup>

                        <button className="mt-3 btn btn-primary btn-block btn-lg btn-signin background-main"
                                type="submit" onClick={this.submit}>
                            {T('SIGN UP')}
                        </button>

                    </form>

                    <div className="mt-3 w-100 d-inline-flex align-items-center">
                        <div className="grey-stripe w-100"/>
                        <p className="txt-log-wersal mt-4 text-center mb-4 px-3">
                            {T('OR')}
                        </p>
                        <div className="grey-stripe w-100"/>
                    </div>

                    <NavLink exact to="/consultant/login"
                             className="ebok-color btn-border-color btn-reg w-100 d-flex justify-content-center align-items-center pointer">
                        {T('LOG IN')}
                    </NavLink>

                </div>
            </div>

        </>

    }


}


export default connect(mapStorageToProps, mapStorageDispatchToProps)(ViewConsultantRegistration);
